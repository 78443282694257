import { FunctionComponent, useEffect } from 'react'
import { IonReactRouter } from '@ionic/react-router'
import { IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { Route } from 'react-router-dom'
import AuthPage from '../../pages/Auth'
import HomePage from '../../pages/Home'
import DailyQuiz from '../../pages/DailyQuiz'
import { useBoundStore } from '../../stores/store'
import { Redirect } from 'react-router'
import trophy from '../../assets/icons/trophy.png'
import homeIcon from '../../assets/icons/home.png'
import statsIcon from '../../assets/icons/stats.png'
import ProviderAuth from '../../pages/Auth/ProviderAuth'
import OAuthScreen from '../../pages/Auth/OAuthScreen'
import AuthGateRoute from './AuthGateRoute'
import QuizGateRoute from './QuizGateRoute'
import UserStats from '../../pages/UserStats'
import Feedback from '../../pages/Feedback'
import PasswordRecovery from '../../pages/Auth/PlainAuth/PasswordRecovery'
import HighscoresLeaderboard from '../../pages/HighscoresLeaderboard'

const TabRouter: FunctionComponent = () => {
    const user = useBoundStore((state) => state.user)
    const accessToken = useBoundStore((state) => state.accessToken)
    useEffect(() => {}, [])
    return (
        <IonReactRouter>
            <IonTabs>
                <IonRouterOutlet>
                    <Route
                        exact
                        path={'/auth'}
                        render={(props) => {
                            if (user && accessToken) {
                                return <Redirect to={'/home'} />
                            }
                            if (!user && accessToken) {
                                return <AuthPage />
                            }
                            return <Redirect to={'/providerAuth'} />
                        }}
                    />

                    <Route exact path={'/welcome'}>
                        <OAuthScreen />
                    </Route>
                    <Route exact path={'/password-recovery'}>
                        <PasswordRecovery />
                    </Route>
                    <Route
                        exact
                        path={'/providerAuth'}
                        render={(props) => {
                            if (user && accessToken) {
                                return <Redirect to={'/home'} />
                            }
                            if (!user && accessToken) {
                                return <Redirect to={'/auth'} />
                            }
                            return <ProviderAuth />
                        }}
                    />

                    <AuthGateRoute path={'/home'} component={<HomePage />} />
                    <AuthGateRoute path={'/stats'} component={<UserStats />} />
                    <AuthGateRoute
                        path={'/feedback'}
                        component={<Feedback />}
                    />
                    <QuizGateRoute
                        path={'/daily'}
                        component={
                            <AuthGateRoute
                                path={'/daily'}
                                component={<DailyQuiz />}
                            />
                        }
                    />

                    <AuthGateRoute
                        path={'/results'}
                        component={<HighscoresLeaderboard />}
                    />

                    <Redirect exact to={'/providerAuth'} from={'/'} />
                </IonRouterOutlet>
                <IonTabBar slot="bottom" className={'h-16 tab-bar-bg'}>
                    <IonTabButton tab="tab1" href="/results">
                        <img src={trophy} className={'tab-icon w-10 h-10'} />
                    </IonTabButton>
                    <IonTabButton tab="home" href="/home">
                        <img src={homeIcon} className={'tab-icon w-10 h-10'} />
                    </IonTabButton>

                    <IonTabButton tab="tab3" href="/stats">
                        <img src={statsIcon} className={'tab-icon w-10 h-10'} />
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>
        </IonReactRouter>
    )
}

export default TabRouter
