import { FunctionComponent } from 'react'
import { IonPage } from '@ionic/react'
import PageWrapper from '../../components/PageWrapper'
import Header from '../../components/atoms/Header'
import ComposableLeaderboard from '../../components/atoms/ComposableLeaderboard'
import { useTranslation } from 'react-i18next'

const HighscoresLeaderboard: FunctionComponent = () => {
    const { t } = useTranslation()
    return (
        <IonPage>
            <Header />

            <PageWrapper>
                <ComposableLeaderboard />
            </PageWrapper>
        </IonPage>
    )
}

export default HighscoresLeaderboard
