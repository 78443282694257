import { FunctionComponent, ReactNode } from 'react'
import clsx from 'clsx'
import { IonIcon } from '@ionic/react'
import { cloudUploadOutline } from 'ionicons/icons'
import { motion } from 'framer-motion'

interface IProps {
    onClick: () => void
    color: 'yellow' | 'green' | 'purple' | 'red' | 'blue'
    children: ReactNode
    disabled?: boolean
    rightIcon?: ReactNode

    additionalClasses?: string

    loading?: boolean
}

const colors = {
    yellow: '#FFF731',
    green: '#00ec31',
}

const BaseButton: FunctionComponent<IProps> = (props) => {
    const handleClick = () => {
        props.onClick()
    }

    const classNames = clsx(
        ` button-shadow-${props.color} relative px-4 opacity-75 w-full   mx-auto py-2 font-tilt  disabled:text-white   disabled:opacity-40 disabled:bg-opacity-40 button-shadow  `,
        `text-metal-${props.color} bg-metal-${props.color} bg-opacity-60   opacity-100 rounded-full text-2xl border-4 border-solid border-metal-${props.color}  `,

        props.additionalClasses
    )
    const scaleVariants = {
        initial: { scale: 1 },
        whileTap: { scale: 1.05 },
    }
    return (
        <motion.button
            disabled={props.disabled}
            onClick={handleClick}
            className={classNames}
            variants={scaleVariants}
            whileTap="whileTap" // Applica l'animazione di scale quando l'elemento è premuto
            initial="initial"
        >
            {props.loading && (
                <IonIcon icon={cloudUploadOutline} className="animate-pulse" />
            )}
            {!props.loading && props.children}
            <div>{props.rightIcon}</div>
        </motion.button>
    )
}

export default BaseButton
