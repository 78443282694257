import { FunctionComponent, useState } from 'react'
import SlideWrapper from '../atoms/Slides/SlideWrapper'
import { useTranslation } from 'react-i18next'
import BaseButton from '../atoms/Button/BaseButton'
import { useSwiper } from 'swiper/react'
import AvatarPicker from '../AvatarPicker'
import { toast } from 'react-toastify'

interface IProps {
    canSubmit: boolean
    onConfirm: () => void

    onAvatarSelected: (avatarId: number) => void
}

const AuthCharacterSlide: FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation()
    const swiper = useSwiper()
    const [isLoading, setIsLoading] = useState(false)

    const confirm = async () => {
        try {
            setIsLoading(true)
            await props.onConfirm()
        } catch (e) {
            toast((e as any).message, { type: 'error' })
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <SlideWrapper className={'h-2/3'}>
            <div className={'flex flex-col items-center  gap-4'}>
                <h1>{t('select-a-character')}</h1>
                <h3 className={'text-center'}>
                    {t('choose-your-literary-sidekick-for-your-quiz-adventure')}
                </h3>
            </div>

            <AvatarPicker
                onSelected={(avatarId) => props.onAvatarSelected(avatarId)}
            />

            <BaseButton
                onClick={confirm}
                loading={isLoading}
                color={'yellow'}
                disabled={!props.canSubmit}
            >
                {t('confirm')}
            </BaseButton>
        </SlideWrapper>
    )
}

export default AuthCharacterSlide
