import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import Hotjar from '@hotjar/browser'
import { HOTJAR_SITE_ID } from '../../config'

interface IProps {
    children: ReactNode
}

const Analytics: FunctionComponent<IProps> = ({ children }) => {
    const [isHotjarInitialized, setIsHotjarInitialized] = useState(false)
    useEffect(() => {
        if (isHotjarInitialized) return
        const siteId = HOTJAR_SITE_ID
        const hotjarVersion = 6
        setIsHotjarInitialized(Hotjar.init(parseInt(siteId), hotjarVersion))
        console.log('Hotjar initialized')
    }, [])

    return <>{children}</>
}

export default Analytics
