import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react'
import { Dialog, DialogBody } from '@material-tailwind/react'
import { IonIcon } from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'
import mainBg from '../../../assets/backgrounds/main-bg.png'

interface IProps {
    headerText: string
    children: React.ReactNode

    onClosed?: () => void
}

export interface DialogRef {
    openDialog: () => void
    closeDialog: () => void
}

const BaseDialog = (props: IProps, ref: Ref<DialogRef>) => {
    const [open, setOpen] = useState(false)

    // Expose the setOpen function to the parent component through the ref
    useImperativeHandle(ref, () => ({
        openDialog: () => setOpen(true),
        closeDialog: () => setOpen(false),
    }))

    const onClose = () => {
        setOpen(false)
        props.onClosed?.()
    }

    return (
        <Dialog
            style={{
                backgroundImage: `url(${mainBg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
            }}
            placeholder={'Header'}
            className={'bg-indigo-800'}
            size={'sm'}
            open={open}
            handler={onClose}
        >
            <IonIcon
                className={
                    'text-metal-yellow w-8 h-8 shadow-2xl fixed top-4 right-4 z-10'
                }
                slot={'end'}
                icon={closeCircleOutline}
                onClick={onClose}
            />

            <DialogBody placeholder className={'w-full h-full'}>
                {props.children}
            </DialogBody>
        </Dialog>
    )
}

export default forwardRef<DialogRef, IProps>(BaseDialog)
