import React, { FunctionComponent, useEffect, useState } from 'react'
import clsx from 'clsx'

interface IProps {
    avatarId: number
    isRounded?: boolean
    additionalClasses?: string
    onClick?: () => void

    id?: string
}

const UserAvatar: FunctionComponent<IProps> = ({
    avatarId,
    isRounded,
    additionalClasses,
    onClick,
    id,
}) => {
    const [choosenAvatar, setChoosenAvatar] = useState<string | null>(null)

    useEffect(() => {
        const loadAvatar = async () => {
            try {
                const avatarModule = isRounded
                    ? await import(
                          `../../../assets/avatars/rounded/avatar-${avatarId}.png`
                      )
                    : await import(
                          `../../../assets/avatars/avatar-${avatarId}.svg`
                      )
                setChoosenAvatar(avatarModule.default)
            } catch (error) {
                console.error('Error loading avatar:', error)
            }
        }

        loadAvatar()
    }, [avatarId, isRounded])

    if (!choosenAvatar) {
        return null
    }

    return (
        <img
            id={id}
            onClick={onClick}
            src={choosenAvatar}
            alt="user avatar"
            className={clsx(isRounded && 'rounded-full', additionalClasses)}
        />
    )
}

export default UserAvatar
