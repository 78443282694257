import { FunctionComponent, useEffect } from 'react'
import { IonPage } from '@ionic/react'
import PasswordRecoveryForm from '../../../../components/Auth/PlainAuth/PasswordRecoveryForm'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router'
import PageWrapper from '../../../../components/PageWrapper'

const PasswordRecoveryPage: FunctionComponent = () => {
    const history = useHistory()
    useEffect(() => {
        console.log('SEARCH', window.location)
    }, [])
    const onSuccess = () => {
        toast('Successfully recovered password!', { type: 'success' })
        history.push('/home')
    }

    const onError = (error: string) => {
        toast(error, { type: 'error' })
        console.error(error)
    }
    return (
        <IonPage>
            <PageWrapper>
                <div
                    className={
                        'w-full h-full flex items-center justify-center p-4'
                    }
                >
                    <PasswordRecoveryForm
                        onSuccess={onSuccess}
                        onError={onError}
                    />
                </div>
            </PageWrapper>
        </IonPage>
    )
}

export default PasswordRecoveryPage
