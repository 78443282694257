import { FunctionComponent, useMemo } from 'react'
import UserAvatar from '../atoms/UserAvatar'
import CountryFlag from '../atoms/CountryFlag'
import { useTranslation } from 'react-i18next'
import { useBoundStore } from '../../stores/store'
import BaseButton from '../atoms/Button/BaseButton'
import {
    calculateTotalScoreQuestionLeaderboard,
    calculateUserRank,
} from './QuizQuestion/calculateLeaderboards'
import useDailyQuizStats from '../../hooks/useDailyQuizStats'
import Loading from '../atoms/Loading'

interface IProps {
    onContinue?: () => void
    isSaving?: boolean
}

const QuizResults: FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation()
    const user = useBoundStore((state) => state.user)
    const userScore = useBoundStore((state) => state.userScore)
    const quiz = useBoundStore((state) => state.quizData)
    const accessToken = useBoundStore((state) => state.accessToken)
    const {dailyQuizStats, isLoading} = useDailyQuizStats(quiz?.id,accessToken)


    const userStats = useMemo(() => {
        return {
            totalScore: userScore ?? 0,
            username: user!.username,
            country_code: user!.country.code,
        }
    },[user, userScore])


    const finalLeaderboard = useMemo(() => {
        if(!dailyQuizStats?.totalScoreRanking) return []
        return calculateTotalScoreQuestionLeaderboard(userStats, dailyQuizStats.totalScoreRanking)
    }, [dailyQuizStats])
    const calculateRank =  useMemo(() => {
        if(!dailyQuizStats?.totalScoreRanking) return 0
        return calculateUserRank(userStats, dailyQuizStats.totalScoreRanking)
    },[dailyQuizStats])

    if(isLoading || !dailyQuizStats) return <Loading />

    if (!user) return null
    return (
        <div
            className={
                'w-full h-screen overflow-hidden flex quiz-bg flex-col   gap-5 px-8 pt-4 pb-4 '
            }
        >
            <div>
                <h1 className={'text-center text-2xl '}>{t('quiz-results')}</h1>
                {/* today date formattedd dd.mm.yyyy */}
                <h6 className={'text-center text-white'}>
                    {new Date().toLocaleDateString('en-GB').replace(/\//g, '.')}
                </h6>
            </div>

            <div
                className={
                    'flex flex-row w-full gap-4 justify-between items-center mb-6  '
                }
            >
                <div className={'flex flex-row gap-2 items-center '}>
                    <UserAvatar
                        avatarId={user.avatarId}
                        additionalClasses={'w-20'}
                    />
                    <div className={'flex flex-col '}>
                        <p className={'text-2xl text-white'}>{user.username}</p>
                        <p className={'text-white'}>#{calculateRank}</p>
                    </div>
                </div>
                <p className={'text-2xl text-white'}>{userScore}</p>
            </div>
            <div className={'h-3/5 overflow-y-scroll  flex flex-col gap-8'}>
                {finalLeaderboard.map((user, index) => {
                    return (
                        <div
                            key={index}
                            className="flex flex-row w-full gap-4 justify-between items-center"
                        >
                            <div className="flex flex-row gap-5 items-center flex-start w-3/4">
                                <div className="flex flex-row items-center gap-2">
                                    <p className="text-lg text-white flex-shrink-0">
                                        {index + 1}.
                                    </p>
                                    <div className={'flex-shrink-0'}>
                                        <CountryFlag
                                            country={{
                                                flagId: user.country_code.toLowerCase(),
                                            }}
                                            height="h-10"
                                            width="w-10"
                                        />
                                    </div>
                                </div>
                                <p className="text-xl text-white text-ellipsis overflow-hidden whitespace-nowrap">
                                    {user.username}
                                </p>
                            </div>
                            <p className="text-2xl text-white">
                                {user.totalScore}
                            </p>
                        </div>
                    )
                })}
            </div>
            {props.onContinue && (
                <BaseButton onClick={props.onContinue} color={'yellow'} disabled={props.isSaving} loading={props.isSaving}>
                    {' '}
                    {t('done')}{' '}
                </BaseButton>
            )}
        </div>
    )
}

export default QuizResults
