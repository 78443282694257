import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import BaseButton from '../atoms/Button/BaseButton'
import { useBoundStore } from '../../stores/store'
import useDailyQuizStats from '../../hooks/useDailyQuizStats'
import Loading from '../atoms/Loading'

const QuizIntro: FunctionComponent = () => {
    const { t } = useTranslation()
    const setQuizStep = useBoundStore((state) => state.setStep)
    const quiz = useBoundStore((state) => state.quizData)
    const accessToken = useBoundStore((state) => state.accessToken)
    const {dailyQuizStats, isLoading,isError} = useDailyQuizStats(quiz?.id,accessToken)
    const setChoicePercentage = useBoundStore(
      (state) => state.setChoicesPercentage
    )
    const setTop20ByScore = useBoundStore((state) => state.setTop20ByScore)

    const setQuestionsScoreByTapTimes = useBoundStore(
      (state) => state.setQuestionsTimeSpent
    )

    const setQuestionsScoreByUser = useBoundStore(
      (state) => state.setQuestionsScoreByUser
    )


    if(isLoading || !dailyQuizStats) return <Loading />

    if(isError) return <div>error</div>
    const startQuiz = async () => {
        setChoicePercentage(dailyQuizStats.choicesAnalytics)
        setTop20ByScore(dailyQuizStats.totalScoreRanking)
        setQuestionsScoreByTapTimes(dailyQuizStats.questionsTimeToTapRanking)
        setQuestionsScoreByUser(dailyQuizStats.questionsScoreRanking)
        setQuizStep('questions')
    }
    return (
        <div
            className={
                'flex flex-col items-center h-full justify-around gap-8  px-8 text-center'
            }
        >
            <div className={'flex flex-col gap-12'}>
                <h1 className={'text-4xl'}>
                    {t('welcome-to-your-daily-quiz')}
                </h1>
                <h2 className={'text-2xl'}>{t('ten-questions-await')}</h2>
            </div>
            <BaseButton onClick={startQuiz} color={'yellow'}>
                {t('i-m-ready')}
            </BaseButton>
        </div>
    )
}

export default QuizIntro
