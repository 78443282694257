import React, { ChangeEvent, forwardRef, useEffect, useState } from 'react'

type IProps = {
    onClick?: () => void
    onChange?: (value: string) => void
    defaultValue?: string
    overrideValue?: string
    placeholder?: string
    minValue?: number
    maxValue?: number
    value?: string | number | undefined
    type: 'text' | 'number' | 'email' | 'password'
    additionalClasses?: string
    disabled?: boolean

    textColor?: string
}

// Usa forwardRef per avvolgere il tuo componente e ricevere la ref come secondo parametro
const TextField = forwardRef<HTMLInputElement, IProps>((props, ref) => {
    const [value, setValue] = useState<string | number | undefined>(
        props.defaultValue
    )

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!props.overrideValue) {
            setValue(e.target.value)
        }
        props.onChange?.(e.target.value)
    }

    useEffect(() => {
        setValue(props.overrideValue)
    }, [props.overrideValue])

    return (
        <input
            className={`${props.additionalClasses || ''} rounded-full  py-4 px-4 disabled:opacity-75 ${props.textColor || 'text-white'} text-xl w-full bg-black/50 outline-none input-shadow `}
            ref={ref}
            disabled={props.disabled}
            min={props.minValue}
            max={props.maxValue}
            defaultValue={props.defaultValue}
            value={props.value ?? value}
            placeholder={props.placeholder}
            type={props.type}
            onClick={props.onClick}
            onChange={onChange}
        />
    )
})

export default TextField
