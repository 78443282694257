import { FunctionComponent, ReactNode } from 'react'
import Logo from '../atoms/Logo'

interface IProps {
    children: string | ReactNode
}

const BlockingMessage: FunctionComponent<IProps> = (props) => {
    return (
        <div
            className={
                'w-full h-full flex flex-col items-center justify-center gap-8'
            }
        >
            <Logo additionalStyle={{ marginTop: '-60px', width: '150px' }} />
            {typeof props.children === 'string' ? (
                <h1 className={'text-center text-2xl py-4 '}>
                    {props.children}
                </h1>
            ) : (
                props.children
            )}
        </div>
    )
}

export default BlockingMessage
