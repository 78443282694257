import { StateCreator } from 'zustand'
import {
    Quiz,
    QuizChoice,
    UserDailyQuizResults,
    UserQuiz,
    UserQuizQuestion,
} from '../types/quiz'
import { immer } from 'zustand/middleware/immer'
import { shuffleArray } from '../hooks/useDailyQuiz'

interface QuizState {
    quizData?: Quiz

    quiz: UserQuiz
    step: 'intro' | 'questions' | 'results'
    currentQuestion?: UserQuizQuestion
    expired: boolean

    userScore: number

    questionScore: number
}

type QuizActions = {
    next: () => void

    setDailyQuiz: (quiz: Quiz) => void

    setStep: (step: 'intro' | 'questions' | 'results') => void

    setUserQuizResults: (results: UserDailyQuizResults) => void
    selectAnswer: (choice: QuizChoice) => void
    setCurrentQuestion: (question: UserQuizQuestion) => void

    setCurrentQuestionStep: (step: UserQuizQuestion['step']) => void

    addAnswer: (question: UserQuizQuestion) => void

    setUserScore: (score: number, correct: boolean) => void

    setTotalScore: (score: number) => void

    clear: () => void

    clearQuiz: () => void
}
export type QuizSlice = QuizState & QuizActions

export const createQuizSlice: StateCreator<
    QuizSlice,
    [],
    [['zustand/immer', never]]
> = immer((set) => ({
    quizData: undefined,
    quiz: {
        id: undefined,
        startedAt: undefined,
        finishedAt: undefined,
        questions: [],
        planned_at: '',
    },
    step: 'intro',
    currentQuestion: undefined,
    expired: false,
    userScore: 0,
    questionScore: 0,

    setTotalScore: (score) =>
        set((state) => {
            state.userScore = score
        }),

    clearQuiz: () =>
        set((state) => {
            state.quizData = undefined
            state.quiz = {
                id: undefined,
                startedAt: undefined,
                finishedAt: undefined,
                questions: [],
                planned_at: '',
            }
            state.step = 'intro'
            state.currentQuestion = undefined
            state.expired = false
            state.userScore = 0
        }),

    setUserQuizResults: (userDailyQuizResults) =>
        set((state) => {
            state.quiz = {
                ...userDailyQuizResults,
                planned_at: new Date().toString(),
                questions: userDailyQuizResults.results.map((res, index) => {
                    return {
                        id: res.questionId,
                        selectedAnswer: res.choiceId,
                        timeSpent: res.timeSpent,
                        questionIndex: index,
                        score: res.score,
                        step: 'leaderboardScore',
                        text: '',
                        choices: [],
                    }
                }),
            }
            state.step = 'results'
        }),

    setUserScore: (questionScore: number, correct) =>
        set((state) => {
            if (correct) {
                state.userScore = state.userScore + questionScore
                state.questionScore = questionScore
            } else {
                state.questionScore = 0
            }
        }),

    addAnswer: (question) =>
        set((state) => {
            console.log('QUESTION IS', question)
            state.quiz.questions.push(question)
            state.currentQuestion = question
        }),

    clear: () =>
        set((state) => {
            state.quizData = undefined
            state.quiz = {
                id: undefined,
                startedAt: undefined,
                finishedAt: undefined,
                questions: [],
                planned_at: '',
            }
            state.step = 'intro'
            state.currentQuestion = undefined
            state.expired = false
            state.userScore = 0
        }),

    setDailyQuiz: (quiz) =>
        set((state) => {
            const newQuiz = JSON.parse(JSON.stringify(quiz)) as Quiz
            newQuiz.questions = newQuiz.questions.map((question) => {
                return {
                    ...question,
                    choices: shuffleArray(question.choices),
                }
            })

            state.quizData = newQuiz

            state.step = 'intro'

            state.quiz.id = quiz.id

            state.currentQuestion = {
                ...quiz.questions[0],
                timeSpent: 0,
                step: 'question',
                score: 0,
                questionIndex: 0,
            }
        }),

    setCurrentQuestionStep: (step) =>
        set((state) => {
            state.currentQuestion!.step = step
        }),

    setStep: (step) =>
        set((state) => {
            state.step = step
        }),

    next: () =>
        set((state) => {
            const nextQuestion =
                state.quizData?.questions[
                    state.currentQuestion!.questionIndex + 1
                ]

            if (nextQuestion) {
                state.currentQuestion = {
                    ...nextQuestion,
                    timeSpent: 0,
                    score: 0,
                    step: 'question',
                    questionIndex: state.currentQuestion!.questionIndex + 1,
                }
            } else {
                state.step = 'results'
            }
        }),

    selectAnswer: (choice) =>
        set((state) => {
            state.currentQuestion!.selectedAnswer = choice.id
        }),

    setCurrentQuestion: (question) =>
        set((state) => {
            state.currentQuestion = {
                ...question,
                timeSpent: 0,
            }
        }),
}))
