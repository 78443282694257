import { MyStats } from '../types/stats'

export const orderWeeklyUserResults = (userResults: MyStats['userResults']) => {
    const dates: string[] = []
    const orderedResults = []
    const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
    const today = new Date()
    const dayOfWeek = today.getDay()
    const diffToMonday = (dayOfWeek + 6) % 7
    const lastMonday = new Date(today)
    lastMonday.setDate(today.getDate() - diffToMonday)
    const nextSunday = new Date(lastMonday)
    nextSunday.setDate(lastMonday.getDate() + 6)
    for (let i = 0; i < 7; i++) {
        const date = new Date(lastMonday)
        date.setDate(lastMonday.getDate() + i)
        dates.push(date.toISOString().split('T')[0])
    }

    for (let i = 0; i < 7; i++) {
        const result = userResults.find((result) => {
            const resultDate = new Date(result.created_at)
                .toISOString()
                .split('T')[0]
            return resultDate === dates[i]
        })
        if (!result) {
            orderedResults.push({
                created_at: dates[i],
                totalScore: 0,
                done: false,
                name: days[i],
            })
        } else {
            orderedResults.push({
                ...result,

                done: true,
                name: days[i],
            })
        }
    }
    return orderedResults
}
