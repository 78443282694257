import { FunctionComponent, useEffect, useState } from 'react'
import { useBoundStore } from '../../../stores/store'
import ComposableFilters, { ComposableFiltersProps } from '../ComposableFilters'
import BaseButton from '../Button/BaseButton'
import ComposableLeaderboardPage from './ComposableLeaderboardPage'
import useHighscores from '../../../hooks/useHighscores'
import UserComposableRanking from '../UserComposableRanking'
import { useTranslation } from 'react-i18next'

export interface ComposableLeaderboardProps {
    rankings: {
        country_code: string
        username: string
        games: number
        points: number
        rank: number
    }[]
}

const ComposableLeaderboard: FunctionComponent = () => {
    const { t } = useTranslation()
    const labels: ComposableFiltersProps['labels'] = [
        { name: 'today', value: 'Today' },
        { name: 'week', value: 'Week' },
        { name: 'month', value: 'Month' },
        { name: 'all', value: 'All time' },
    ]
    const [type, setType] = useState<'today' | 'week' | 'month' | 'all'>(
        'today'
    )
    const [cnt, setCnt] = useState(0)

    const accessToken = useBoundStore((state) => state.accessToken)
    const {
        rankings,
        isLoading,
        error,
        isValidating,
        total: countResults,
    } = useHighscores(accessToken!, type, cnt)
    useEffect(() => {
        setCnt(0)
    }, [type])
    const paginate = () => {
        setCnt(cnt + 1)
    }

    const pages = []
    for (let i = 0; i < cnt + 1; i++) {
        pages.push(
            <ComposableLeaderboardPage
                rankings={rankings}
                key={i}
                pageIndex={i}
                type={type}
            />
        )
    }
    return (
        <div
            className={
                'w-full h-full  pt-8 flex flex-col gap-4 overflow-hidden '
            }
        >
            <div
                className={
                    'flex flex-col justify-between items-center gap-4 px-4'
                }
            >
                <h2 className={'text-2xl'}> {t('world-leaderboard')}</h2>
            </div>
            <UserComposableRanking type={type} />

            <ComposableFilters
                labels={labels}
                onChange={(value) => {
                    setType(value as 'today' | 'week' | 'month' | 'all')
                }}
            />
            <div
                className={
                    'flex flex-row w-full gap-4 justify-end text-white font-tilt pr-8'
                }
            >
                <span>Games</span>
                <span>Points</span>
            </div>
            <div
                className={
                    'w-full h-full  flex stats-card flex-col overflow-y-scroll   gap-5 pl-12 pr-8 pt-4 pb-4 '
                }
            >
                {pages}
                {countResults && countResults / ((cnt + 1) * 10) > 1 && (
                    <BaseButton
                        loading={isLoading}
                        onClick={paginate}
                        color={'yellow'}
                    >
                        Load more
                    </BaseButton>
                )}
            </div>
        </div>
    )
}

export default ComposableLeaderboard
