import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { IonIcon } from '@ionic/react'
import { chevronBack, chevronForward } from 'ionicons/icons'
import UserAvatar from '../atoms/UserAvatar'

interface IProps {
    onSelected: (avatarId: number) => void
}

const AvatarPicker: FunctionComponent<IProps> = (props) => {
    const avatarsCount = 15
    const [selectedAvatar, setSelectedAvatar] = useState(1)
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        props.onSelected(selectedAvatar)
    },[])
    const handleAvatarClick = (index: number) => {
        props.onSelected(index)
        setSelectedAvatar(index)
        const avatarElement = containerRef?.current?.querySelector(
            `#avatar-${index}`
        )
        avatarElement?.scrollIntoView({ behavior: 'smooth', inline: 'end' })
    }

    return (
        <div
            className={
                'flex flex-col gap-2 w-full h-full justify-around flex-grow '
            }
        >
            <div
                className={
                    'flex flex-row gap-2 items-center justify-center relative'
                }
            >
                {selectedAvatar > 1 && (
                    <IonIcon
                        onClick={() => handleAvatarClick(selectedAvatar - 1)}
                        icon={chevronBack}
                        className={
                            'text-4xl text-white opacity-50 w-1/6 absolute -left-5 top-50'
                        }
                    />
                )}

                <UserAvatar
                    avatarId={selectedAvatar}
                    additionalClasses={'w-200px'}
                />
                {selectedAvatar < avatarsCount && (
                    <IonIcon
                        onClick={() => handleAvatarClick(selectedAvatar + 1)}
                        icon={chevronForward}
                        className={
                            'text-4xl text-white opacity-50 absolute -right-5 top-50'
                        }
                    />
                )}
            </div>
            <div
                className={
                    'flex flex-row gap-2 items-center  w-full h-12 overflow-x-scroll scrollbar-hide'
                }
                ref={containerRef}
            >
                {Array(avatarsCount)
                    .fill(0)
                    .map((_, index) => (
                        <UserAvatar
                            onClick={() => handleAvatarClick(index + 1)}
                            key={index}
                            id={`avatar-${index}`}
                            additionalClasses={
                                selectedAvatar === index + 1
                                    ? 'w-8 h-8'
                                    : 'w-6 h-6  '
                            }
                            avatarId={index + 1}
                            isRounded={true}
                        />
                    ))}
            </div>
        </div>
    )
}

export default AvatarPicker
