import { FunctionComponent } from 'react'
import BaseButton from '../../../components/atoms/Button/BaseButton'
import { useAuth } from '../../../components/AuthContext'
import { useTranslation } from 'react-i18next'

interface IProps {
    onAuth: (response: any, token: string) => void
}

const GoogleAuth: FunctionComponent<IProps> = (props) => {
    const { signIn } = useAuth()
    const { t } = useTranslation()
    const logGoogleUser = async () => {
        const response = await signIn()
    }

    return (
        <BaseButton
            onClick={logGoogleUser}
            color={'blue'}
            additionalClasses={'w-4/5'}
        >
            {t('continue-with-google')}
        </BaseButton>
    )
}

export default GoogleAuth
