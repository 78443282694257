import createFetcher from './fetcher'
import useSWR from 'swr'

interface UserRankResponse {
    rank: any[]
    count: number
}

function useUserRank(
    accessToken: string,
    type: 'today' | 'week' | 'month' | 'all'
) {
    const fetcher = createFetcher(accessToken!)
    const date = new Date().toISOString().split('T')[0]

    const { data, error, mutate, isLoading, isValidating } =
        useSWR<UserRankResponse>(
            '/user-rank?date=' + date + '&type=' + type,
            fetcher,
            {
                revalidateOnFocus: false,
            }
        )

    return {
        rank: data?.rank?.length ? data?.rank?.[0].rank : 0,
        totalScore: data?.rank?.length ? data?.rank?.[0].totalscore : 0,
        count: data?.count,
        isLoading: isLoading && !isValidating,
        error: error,
        isValidating: isValidating,
        mutate,
    }
}

export default useUserRank
