import { UserDTO } from '../types/user.dto'

export const signupUserAction = async (user: UserDTO, accessToken: string) => {
    const userRequest = await fetch(
        process.env.REACT_APP_EDGE_FUNCTIONS_URI + '/user',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ user: user }),
        }
    )

    if (userRequest.status === 201 || userRequest.status === 200) {
        return userRequest.json()
    } else {
        throw new Error('Error creating user')
    }
}
