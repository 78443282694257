import {
    createContext,
    FunctionComponent,
    ReactNode,
    useContext,
    useEffect,
} from 'react'
import { supabase } from '../../utils/supabase.utils'
import { useBoundStore } from '../../stores/store'
import { getUserAction } from '../../actions/auth/getUser.action'
import { toast } from 'react-toastify'
import { AuthError, UserResponse } from '@supabase/supabase-js'

const AuthContext = createContext<{
    signIn: () => void
    signInWithEmail: (email: string, password: string) => any
    signOut: () => void
    sendPasswordResetEmail: (
        email: string
    ) => Promise<{ data: {}; error: null } | { data: null; error: AuthError }>
    updateUserPwd: (password: string) => Promise<UserResponse | null>
    user?: any
}>({
    signIn: () => {},
    signOut: () => {},
    signInWithEmail: async () => {},
    sendPasswordResetEmail: async () => {
        return { data: {}, error: null }
    },
    updateUserPwd: async () => null,
    user: null,
})

export function useAuth() {
    return useContext(AuthContext)
}

export const AuthProvider: FunctionComponent<{ children: ReactNode }> = ({
    children,
}) => {
    const setUserOAuth = useBoundStore((state) => state.setUserOAuth)
    const setUserPlainAuth = useBoundStore((state) => state.setUserPlainAuth)
    const login = useBoundStore((state) => state.login)
    const logout = useBoundStore((state) => state.logout)
    useEffect(() => {
        const { data } = supabase.auth.onAuthStateChange(
            async (event, session) => {
                if (event === 'SIGNED_OUT') {
                    localStorage.removeItem('bound-store')
                    window.location.href = '/providerAuth'
                }
                if (event === 'SIGNED_IN' && session) {
                    const providerType = session.user?.identities?.[0].provider
                    if (providerType === 'google') {
                        setUserOAuth(session, session.access_token)
                    } else if (providerType === 'email') {
                        const dbUser = await getUserAction(session.access_token)
                        if (dbUser.length) {
                            login({
                                ...dbUser[0],
                                country: {
                                    name: dbUser[0].country_name,
                                    code: dbUser[0].country_code,
                                },
                            })
                            setUserPlainAuth(session, session.access_token)
                            toast('Welcome back!', { type: 'success' })
                        } else {
                            setUserPlainAuth(session, session.access_token)
                        }
                    }
                }

                if (event === 'PASSWORD_RECOVERY') {
                    console.log('PASSWORD_RECOVERY')
                    console.log(session)
                }
            }
        )

        return () => {
            data.subscription?.unsubscribe()
        }
    }, [])
    const value = {
        signIn: () =>
            supabase.auth.signInWithOAuth({
                provider: 'google',
                options: {
                    queryParams: {
                        prompt: 'select_account',
                    },
                    redirectTo: process.env.REACT_APP_WEB_URI + '/welcome',
                },
            }),
        signInWithEmail: async (email: string, password: string) => {
            try {
                return await supabase.auth.signInWithPassword({
                    email,
                    password,
                })
            } catch (error) {
                console.log('ERROR')
                throw error
            }
        },
        signOut: () => {
            supabase.auth.signOut()
            logout()
        },
        async sendPasswordResetEmail(
            email: string
        ): Promise<
            { data: {}; error: null } | { data: null; error: AuthError }
        > {
            try {
                return await supabase.auth.resetPasswordForEmail(email, {
                    redirectTo:
                        process.env.REACT_APP_WEB_URI + '/password-recovery',
                })
            } catch (error) {
                console.error('Error sending password recovery email:', error)
                throw error
            }
        },

        async updateUserPwd(password: string): Promise<UserResponse | null> {
            return await supabase.auth.updateUser({
                password: password,
            })
        },
    }
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
