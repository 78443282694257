import { FunctionComponent } from 'react'
import { useBoundStore } from '../../../stores/store'
import useHighscores from '../../../hooks/useHighscores'
import ComposableLeaderboardItem from './ComposableLeaderboardItem'

interface IProps {
    pageIndex: number
    type: 'today' | 'week' | 'month' | 'all'
    rankings: undefined | any[]
}

const ComposableLeaderboardPage: FunctionComponent<IProps> = (props) => {
    const accessToken = useBoundStore((state) => state.accessToken)
    const offset = props.pageIndex === 0 ? 0 : props.pageIndex + 5
    const limit = props.pageIndex === 0 ? 5 : offset + 5
    const {
        rankings,
        isLoading,
        error,
        isValidating,
        total: countResults,
    } = useHighscores(accessToken!, props.type, props.pageIndex)

    return rankings?.map((ranking, index) => (
        <ComposableLeaderboardItem
            index={ranking.rank}
            country_code={ranking.country_code}
            username={ranking.username}
            games={ranking.games}
            points={ranking.points}
            key={index}
        />
    ))
}

export default ComposableLeaderboardPage
