import { FeedbackAnswers } from '../../types/feedback'

export const sendUserFeedback: (
    accessToken: string,
    answers: FeedbackAnswers[],
    feedbackId: number
) => Promise<void> = async (accessToken, answers, feedbackId) => {
    const saveFeedback = await fetch(
        process.env.REACT_APP_EDGE_FUNCTIONS_URI + '/user-feedback',
        {
            method: 'POST',
            body: JSON.stringify({answers, feedbackId}),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }
    )

    if (saveFeedback.status !== 201 && saveFeedback.status !== 200) {
        console.error(
            'Error saving user feedback',
            saveFeedback.status,
            saveFeedback.statusText
        )
        throw new Error('Error saving user feedback')
    }
}
