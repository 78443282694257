import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useBoundStore } from '../../../stores/store'
import UserAvatar from '../../atoms/UserAvatar'
import BaseButton from '../../atoms/Button/BaseButton'
import CountryFlag from '../../atoms/CountryFlag'
import Separator from '../../atoms/Separator'
import {
    calculateTimeToTheTapQuestionLeaderboard,
    calculateUserRank,
    QuestionStats,
} from './calculateLeaderboards'

interface IProps {
    onContinue: () => void
}

const QuestionLeaderboard: FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation()
    const user = useBoundStore((state) => state.user)
    const currentQuestion = useBoundStore((state) => state.currentQuestion)
    const userScore = useBoundStore((state) => state.userScore)
    const top20ByScore = useBoundStore((state) => state.top20ByScore)
    const questionsTimeSpent = useBoundStore(
        (state) => state.questionsTimeSpent
    )

    const currentQuestionTimeSpent = questionsTimeSpent?.find(
        (question) => question.question_id === currentQuestion?.id
    )

    const userStats: QuestionStats = {
        [user!.username]: {
            timeSpent: currentQuestion?.timeSpent || 0,
            country_code: user!.country.code,
        },
    }

    const leaderboard = calculateTimeToTheTapQuestionLeaderboard(
        userStats[user!.username]?.timeSpent !== 0 &&
            currentQuestion?.choices.find(
                (c) => c.id === currentQuestion?.selectedAnswer
            )?.isCorrect
            ? userStats
            : undefined,
        currentQuestionTimeSpent?.stats
    )

    const calculateRank = calculateUserRank(
        {
            totalScore: userScore,
            country_code: user!.country.code,
            username: user!.username,
        },
        top20ByScore
    )

    if (!user || !currentQuestion) return null
    return (
        <div
            className={
                'w-full h-screen overflow-hidden flex flex-col justify-between pb-8 gap-4 pl-2 pr-4'
            }
        >
            <h1 className={'text-center text-3xl'}>{t('time-to-the-tap')}</h1>
            <div
                className={
                    'flex flex-row w-full gap-4 justify-between items-center'
                }
            >
                <div className={'flex flex-row gap-2 items-center '}></div>
            </div>
            <div className={'h-full overflow-y-scroll flex flex-col gap-4'}>
                <div
                    className={
                        'flex flex-row w-full gap-4 justify-between items-center'
                    }
                >
                    <div className={'  flex flex-row gap-2 items-center '}>
                        <div className={'flex flex-row items-center gap-2 '}>
                            <UserAvatar
                                avatarId={user.avatarId}
                                additionalClasses={'w-16'}
                            />
                        </div>
                        <div className={'flex flex-col '}>
                            <p className={'text-2xl text-white'}>
                                {user.username}
                            </p>
                            <p className={'text-white'}>#{calculateRank}</p>
                        </div>
                    </div>
                    <p className={'text-2xl text-white'}>
                        {(currentQuestion?.timeSpent / 1000).toFixed(2)}s
                    </p>
                </div>
                <Separator />
                <div className={'flex flex-col gap-8 items-center  h-full'}>
                    {leaderboard.map((user, index) => {
                        return (
                            <div
                                key={index}
                                className="flex flex-row w-full gap-4 justify-between items-center"
                            >
                                <div className="flex flex-row gap-5 items-center flex-start w-3/4">
                                    <div className="flex flex-row items-center gap-2">
                                        <p className="text-lg text-white flex-shrink-0">
                                            {index + 1}.
                                        </p>
                                        <div className={'flex-shrink-0'}>
                                            <CountryFlag
                                                country={{
                                                    flagId: user.country_code,
                                                }}
                                                height="h-10"
                                                width="w-10"
                                            />
                                        </div>
                                    </div>
                                    <p className="text-xl text-white text-ellipsis overflow-hidden whitespace-nowrap">
                                        {user.username}
                                    </p>
                                </div>
                                <p className="text-2xl text-white">
                                    {user.timeSpent.toFixed(2)}s
                                </p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={'pt-4'}>
                <BaseButton onClick={props.onContinue} color={'yellow'}>
                    {' '}
                    {t('continue')}{' '}
                </BaseButton>
            </div>
        </div>
    )
}

export default QuestionLeaderboard
