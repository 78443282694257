import { FunctionComponent, useState } from 'react'
import { useBoundStore } from '../../../stores/store'
import CountDownBar from '../../atoms/CountDownBar'
import { ANSWER_TIME, QUESTION_TIME } from '../../../config'
import QuestionText from './QuestionText'
import QuizChoiceList from './ChoiceList'
import { UserDailyQuizResults, UserQuizQuestion } from '../../../types/quiz'
import QuestionLeaderboard from './QuestionLeaderboard'
import QuestionScore from './QuestionScore'
import { calculateUserScore } from '../../../utils/calculateUserScore'
import { motion } from 'framer-motion'
import { saveDailyQuizUserResults } from '../../../actions/quiz/saveDailyQuizUserResults.action'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import useStreaks from '../../../hooks/useStreaks'
import Loading from '../../atoms/Loading'

const QuizQuestion: FunctionComponent = () => {
    const currentQuestion = useBoundStore((state) => state.currentQuestion)
    const setCurrentQuestionStep = useBoundStore(
        (state) => state.setCurrentQuestionStep
    )
    const addQuizAnswer = useBoundStore((state) => state.addAnswer)
    const updateUserScore = useBoundStore((state) => state.setUserScore)
    const setNextQuestion = useBoundStore((state) => state.next)
    const setQuizStep = useBoundStore((state) => state.setStep)
    const [time, setTime] = useState(QUESTION_TIME)
    const [startAnswerTime, setStartAnswerTime] = useState<number>(0)
    const [pause, setPause] = useState<boolean>(false)
    const accessToken = useBoundStore((state) => state.accessToken)
    const user = useBoundStore((state) => state.user)
    const userQuiz = useBoundStore((state) => state.quiz)
    const { t } = useTranslation()
    const [isSaving, setIsSaving] = useState(false)
    const userScore = useBoundStore((state) => state.userScore)
    const history = useHistory()
    const { mutate } = useStreaks()

    const [showChoicesStats, setShowChoicesStats] = useState<boolean>(false)
    const [showAnswersOutcome, setShowAnswersOutcome] = useState<
        boolean | undefined
    >(undefined)

    const evaluateQuestionStep = (updateScore?: boolean) => {
        if (currentQuestion?.step === 'question') {
            setPause(false)
            setCurrentQuestionStep('answer')
            setShowAnswersOutcome(undefined)
            setShowChoicesStats(false)
            setTime(ANSWER_TIME)
            setStartAnswerTime(performance.now())
        } else if (currentQuestion?.step === 'answer') {
            if (updateScore) {
                updateUserScore(0, false)
            }
            setShowChoicesStats(true)

            setTimeout(() => {
                setShowAnswersOutcome(true)
            }, 2000)

            setTimeout(() => {
                setCurrentQuestionStep('score')
            }, 3000)
        }
    }

    const onConfirmNextQuestion = () => {
        if ((currentQuestion?.questionIndex ?? 0) + 1 === 10) {
            saveQuizResults()
        } else {
            setNextQuestion()
        }
    }

    const saveQuizResults = async () => {
        setIsSaving(true)
        const startedFunctionTime = new Date().getTime()
        if (!userQuiz || !accessToken) return
        try {
            const quizResults: UserDailyQuizResults = {
                username: user!.username,
                country_code: user!.country.code,
                country_name: user!.country.name,
                dailyQuizId: userQuiz.id!,
                totalScore: userScore,
                results: userQuiz.questions.map((question) => {
                    return {
                        questionId: question.id,
                        choiceId: question.selectedAnswer!,
                        correctChoiceId: question.choices.find(
                            (choice) => choice.isCorrect
                        )!.id,
                        isChoiceCorrect:
                            question.selectedAnswer ===
                            question.choices.find((choice) => choice.isCorrect)!
                                .id,
                        score: question.score,
                        timeSpent: question.timeSpent,
                    }
                }),
            }

            await saveDailyQuizUserResults(accessToken, quizResults)
            const endedFunctionTime = new Date().getTime()

            mutate()
            toast.success(t('quiz-results-saved'))
            setQuizStep('results')
        } catch (e) {
            console.error(e)
            toast((e as any).message, { type: 'error' })
        } finally {
            setIsSaving(false)
        }
    }

    const onUserChoice = (choiceId?: string) => {
        if (!choiceId) {
            updateUserScore(0, false)
            return
        }
        setPause(true)
        const timeSpentMS = performance.now() - startAnswerTime
        const timeSpent = Math.min(timeSpentMS, ANSWER_TIME)
        const isChoiceCorrect =
            choiceId === currentQuestion?.choices.find((c) => c.isCorrect)?.id
        //todo please change
        const questionScore = isChoiceCorrect
            ? calculateUserScore(timeSpent)
            : 0
        console.log('QUESTION SCORE IS', questionScore)
        updateUserScore(questionScore, isChoiceCorrect)

        const userQuestionAnswer: UserQuizQuestion = {
            ...currentQuestion!,
            selectedAnswer: choiceId,
            timeSpent,
            score: isChoiceCorrect ? questionScore : 0,
        }
        addQuizAnswer(userQuestionAnswer)
        evaluateQuestionStep()
    }

    if (!currentQuestion) return null
    if (isSaving) return <Loading />
    return (
        <div
            className={
                'w-screen  h-screen overflow-hidden  quiz-bg px-4 py-2 gap-8'
            }
        >
            {currentQuestion?.step !== 'leaderboard' &&
                currentQuestion?.step !== 'score' &&
                currentQuestion?.step !== 'leaderboardScore' && (
                    <CountDownBar
                        time={time}
                        pause={pause}
                        showProgress={currentQuestion.step === 'answer'}
                        onEnd={() => evaluateQuestionStep(true)}
                    />
                )}
            <div className={'h-full flex flex-col justify-center gap-8'}>
                {currentQuestion?.step !== 'leaderboard' &&
                    currentQuestion?.step !== 'score' &&
                    currentQuestion?.step !== 'leaderboardScore' && (
                        <motion.div
                            initial={{
                                y: currentQuestion?.step === 'answer' ? 100 : 0,
                            }}
                            animate={{ y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <QuestionText text={currentQuestion.text} />
                        </motion.div>
                    )}
                {currentQuestion?.step === 'answer' && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        className={'flex flex-row w-full gap-4 justify-center '}
                    >
                        <QuizChoiceList
                            onChoice={(choiceId: string) =>
                                onUserChoice(choiceId)
                            }
                            showChoicesStats={showChoicesStats}
                            showAnswersOutcome={showAnswersOutcome}
                            choices={currentQuestion.choices}
                        />
                    </motion.div>
                )}
                {currentQuestion?.step === 'leaderboard' && (
                    <QuestionLeaderboard
                        onContinue={() => setCurrentQuestionStep('score')}
                    />
                )}
                {currentQuestion?.step === 'score' && (
                    <QuestionScore onConfirm={onConfirmNextQuestion} />
                )}
                {/*     {currentQuestion?.step === 'leaderboardScore' && (
                    <QuestionLeaderboardScore
                        onContinue={() => setNextQuestion()}
                    />
                )}*/}
            </div>
        </div>
    )
}

export default QuizQuestion
