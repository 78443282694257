import { FunctionComponent, useEffect, useState } from 'react'
import { IonPage } from '@ionic/react'
import Header from '../../components/atoms/Header'
import BaseButton from '../../components/atoms/Button/BaseButton'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useBoundStore } from '../../stores/store'
import Logo from '../../components/atoms/Logo'
import checkIcon from '../../assets/icons/check.svg'
import { toast } from 'react-toastify'
import { useAuth } from '../../components/AuthContext'
import useDailyQuiz from '../../hooks/useDailyQuiz'
import Loading from '../../components/atoms/Loading'
import PageWrapper from '../../components/PageWrapper'

const HomePage: FunctionComponent = () => {
    const [isDailyQuizCompleted, setIsDailyQuizCompleted] = useState(false)
    const history = useHistory()
    const quiz = useBoundStore((state) => state.quiz)
    const quizData = useBoundStore((state) => state.quizData)
    const accessToken = useBoundStore((state) => state.accessToken)
    const todayDate = new Date().toISOString().split('T')[0]

    const shouldFetchDailyQuiz = !quizData
        ? true
        : quizData.planned_at !== todayDate

    const {
        quizData: dailyData,
        isLoading,
        isError,
    } = useDailyQuiz(accessToken, true)

    const setDailyQuiz = useBoundStore((state) => state.setDailyQuiz)
    const clearUserQuizResults = useBoundStore((state) => state.clearQuiz)
    const clearStats = useBoundStore((state) => state.clearStats)
    const setQuizStep = useBoundStore((state) => state.setStep)
    const { signOut } = useAuth()
    const setUserQuizResults = useBoundStore(
        (state) => state.setUserQuizResults
    )
    const setTotalScore = useBoundStore((state) => state.setTotalScore)

    const { t } = useTranslation()

    if (isError) {
        signOut()
        window.location.href = '/providerAuth'
    }

    useEffect(() => {
        if (!dailyData) return

        clearUserQuizResults()
        clearStats()
        setDailyQuiz(dailyData.dailyQuiz)

        if (dailyData?.userResults?.length) {
            setUserQuizResults(dailyData.userResults[0])
            setTotalScore(dailyData.userResults[0].totalScore)
            setQuizStep('results')

            setIsDailyQuizCompleted(true)
        } else {
            setQuizStep('intro')
        }
    }, [dailyData])

    useEffect(() => {
        if (quiz?.questions?.length === 10) {
            setIsDailyQuizCompleted(true)
        } else {
            setIsDailyQuizCompleted(false)
        }
    }, [])

    useEffect(() => {
        if (quiz?.questions?.length === 10) {
            setIsDailyQuizCompleted(true)
        } else {
            setIsDailyQuizCompleted(false)
        }
    }, [])

    if (isLoading && !dailyData && !quizData?.questions?.length)
        return <Loading />

    const icon = isDailyQuizCompleted ? (
        <img
            src={checkIcon}
            alt={'checkmark'}
            className={'w-10 h-10 absolute top-2 right-2'}
        />
    ) : (
        <div
            className={
                'font-titan absolute top-2 right-2 flex flex-row items-center justify-center text-white p-4 w-8 h-8 bg-gradient-to-r from-[#FF4848] to-[#B80000] shadow-2xl rounded-full '
            }
        >
            !
        </div>
    )

    const goToQuiz = () => {
        if (isDailyQuizCompleted)
            return toast(t('you-have-already-completed-the-quiz'), {
                type: 'success',
            })
        history.push('/daily')
    }

    return (
        <>
            <IonPage>
                <Header />
                <PageWrapper>
                    <div className={'flex flex-col h-full gap-12 px-8 pt-4 '}>
                        <Logo />

                        <div className={'flex flex-col gap-6'}>
                            <BaseButton
                                rightIcon={icon}
                                onClick={goToQuiz}
                                color={
                                    isDailyQuizCompleted ? 'green' : 'yellow'
                                }
                            >
                                {t('daily-quiz')}
                            </BaseButton>

                            <BaseButton
                                onClick={() => history.push('/results')}
                                color={'blue'}
                            >
                                {t('quiz-results')}
                            </BaseButton>
                        </div>
                    </div>
                </PageWrapper>
            </IonPage>
        </>
    )
}

export default HomePage
