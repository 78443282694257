import { useTranslation } from 'react-i18next'
import { useBoundStore } from '../../stores/store'
import { FunctionComponent, useMemo, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Pagination, Scrollbar } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react'
import SlideWrapper from '../atoms/Slides/SlideWrapper'
import BaseButton from '../atoms/Button/BaseButton'
import { Rating, Textarea } from '@material-tailwind/react'
import { FeedbackAnswers, FeedbackQuestions } from '../../types/feedback'
import { sendUserFeedback } from '../../actions/feedback/sendUserFeedback.action'
import { toast } from 'react-toastify'

interface IProps {
    onNext: (questionIndex: number) => void
    onEnd: () => void
}

const FeedbackSlider: FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation()
    const user = useBoundStore((state) => state.user)
    const [answers, setAnswers] = useState<FeedbackAnswers[]>([])
    const [swiper, setSwiper] = useState<Swiper>()
    const [currentQuestion, setCurrentQuestion] = useState(0)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1)
    const accessToken = useBoundStore((state) => state.accessToken)
    const isLastQuestion = useMemo(() => {
        return currentQuestionIndex === 4
    }, [answers, currentQuestionIndex])
    const pushAnswer = (answer: FeedbackAnswers) => {
        if (!answers.find((a) => a.questionId === answer.questionId)) {
            setAnswers([...answers, answer])
        } else {
            setAnswers(
                answers.map((a) =>
                    a.questionId === answer.questionId ? answer : a
                )
            )
        }
    }

    const sendFeedback = async () => {
        await sendUserFeedback(accessToken!, answers, answers[0].feedbackId)
    }
    const goNext = async () => {
        if (isLastQuestion) {
            try {
                await sendFeedback()
                toast.success('Feedback sent! Thank you')
            } catch (error) {
                toast.error('Error sending feedback')
                console.log('error', error)
            } finally {
                props.onEnd()
            }
        }
        if (!swiper) return
        setCurrentQuestionIndex(currentQuestionIndex + 1)
        setCurrentQuestion(questions[currentQuestionIndex].questionId)
        swiper.slideNext()
        props.onNext(currentQuestionIndex + 1)
    }
    const isNextButtonDisabled = useMemo(() => {
        const answer = answers.find(
            (answer) => answer.questionId === currentQuestion
        )
        console.log('answer', answer)
        return (
            !answer ||
            answer?.answer.trim() === '' ||
            answer?.answer.length > 500 ||
            !answer?.answer
        )
    }, [answers, currentQuestion, currentQuestionIndex])
    const questions: FeedbackQuestions[] = [
        {
            feedbackId: 1,
            questionId: 1,
            question: t('feedback-1-question-1'),
            type: 'rating',
        },
        {
            feedbackId: 1,
            questionId: 2,
            question: t('feedback-1-question-2'),
            type: 'open',
        },
        {
            feedbackId: 1,
            questionId: 3,
            question: t('feedback-1-question-3'),
            type: 'open',
        },
        {
            feedbackId: 1,
            questionId: 4,
            question: t('feedback-1-question-4'),
            type: 'open',
        },
    ]

    return (
        <Swiper
            className={
                ' w-full h-slide flex items-center justify-center quiz-bg  '
            }
            // install Swiper modules
            modules={[Pagination, Scrollbar]}
            slidesPerView={1}
            allowTouchMove={false}
            onSwiper={(swiper) => setSwiper(swiper)}
        >
            {questions.map((question, index) => (
                <SwiperSlide
                    className={'flex flex-row h-slide mt-4  '}
                    style={{ marginTop: 0 }}
                >
                    <SlideWrapper className={' items-center mt-8 '}>
                        <h2 className={'text-2xl text-center '}>
                            {question.question}
                        </h2>
                        {question.type === 'rating' ? (
                            <Rating
                                className={'flex flex-row gap-4 text-2xl'}
                                placeholder={'rate'}
                                unratedColor={'yellow'}
                                ratedColor={'yellow'}
                                onChange={(value) => {
                                    pushAnswer({
                                        feedbackId: question.feedbackId,
                                        questionId: question.questionId,
                                        question: question.question,
                                        type: question.type,
                                        answer: value.toString(),
                                    })

                                    setCurrentQuestion(question.questionId)
                                }}
                            />
                        ) : (
                            <Textarea
                                placeholder={'What are your thoughts?'}
                                className={
                                    'bg-white rounded-full input-shadow px-8 py-4 text-gray-700'
                                }
                                onChange={(e) => {
                                    pushAnswer({
                                        feedbackId: question.feedbackId,
                                        questionId: question.questionId,
                                        question: question.question,
                                        type: question.type,
                                        answer: e.target.value.toString(),
                                    })
                                    setCurrentQuestion(question.questionId)
                                }}
                            />
                            /* <TextField
type={'text'}
additionalClasses={'bg-white'}
textColor={'text-gray-700'}
onChange={(value) => {
setAnswers([
...answers,
{
feedbackId: question.feedbackId,
questionId: question.questionId,
question: question.question,
type: question.type,
answer: value,
},
])
setCurrentQuestion(currentQuestion + 1)
}}
/>*/
                        )}
                        <BaseButton
                            disabled={isNextButtonDisabled}
                            onClick={goNext}
                            color={'yellow'}
                        >
                            {!isLastQuestion ? 'Next' : 'Done'}
                        </BaseButton>
                    </SlideWrapper>
                </SwiperSlide>
            ))}
        </Swiper>
    )
}

export default FeedbackSlider
