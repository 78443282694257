export const getUserAction = async (accessToken: string) => {
    const userRequest = await fetch(
        process.env.REACT_APP_EDGE_FUNCTIONS_URI + '/user',
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }
    )

    if (userRequest.status === 201 || userRequest.status === 200) {
        return userRequest.json()
    } else {
        console.log(userRequest)
        throw new Error('Error creating user')
    }
}
