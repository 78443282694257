const baseURL = process.env.REACT_APP_EDGE_FUNCTIONS_URI

const createFetcher = (accessToken: string) => (url: string) =>
    fetch(`${baseURL}${url}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            'x-region': 'eu-west-2',
        },
    }).then((res) => {
        if (!res.ok) {
            throw new Error('Network response was not ok')
        }
        return res.json()
    })

export default createFetcher
