import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBoundStore } from '../../../stores/store'
import Separator from '../../atoms/Separator'
import CountUp from 'react-countup'
import { calculateUserRank } from './calculateLeaderboards'
import UserAvatar from '../../atoms/UserAvatar'
import BaseButton from '../../atoms/Button/BaseButton'

interface IProps {
    onConfirm: () => void
}

const QuestionScore: FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation()
    const user = useBoundStore((state) => state.user)
    const userScore = useBoundStore((state) => state.userScore)
    const questionScore = useBoundStore((state) => state.questionScore)
    const [showScore, setShowScore] = useState(false)
    const currentQuestion = useBoundStore((state) => state.currentQuestion)

    const [showNextButton, setShowNextButton] = useState(false)
    const top20ByScore = useBoundStore((state) => state.top20ByScore)
    const calculateRank = calculateUserRank(
        {
            totalScore: userScore,
            username: user!.username,
            country_code: user!.country.code,
        },
        top20ByScore
    )
    const actualCorrectQuestions = useBoundStore(
        (state) => state.quiz.questions
    ).filter(
        (question) =>
            question.choices.find(
                (choice) => choice.id === question.selectedAnswer
            )?.isCorrect
    ).length
    useEffect(() => {
        setTimeout(() => {
            setShowNextButton(true)
        }, 2000)
    }, [])

    if (!user) return null
    return (
        <div
            className={
                'w-full h-screen overflow-hidden flex flex-col items-center  gap-12      pl-2 pr-4 '
            }
        >
            <h1 className={'text-center text-3xl flex-shrink'}>
                {t('your-score')}
            </h1>
            <div className={'flex flex-col gap-6'}>
                <div className={'flex flex-col gap-2 relative  w-full'}>
                    <div
                        className={'flex flex-row justify-center gap-2 pb-4  '}
                    >
                        <UserAvatar
                            avatarId={user.avatarId}
                            additionalClasses={'absolute top-0 left-0 w-24'}
                        />
                        <div
                            className={
                                'flex flex-col gap-4 justify-center items-center'
                            }
                        >
                            <p className={'text-4xl text-white '}>
                                {user.username}
                            </p>
                            <div
                                className={
                                    'flex flex-col items-center justify-center gap-2'
                                }
                            >
                                <h1 className={'text-xl opacity-75 '}>Score</h1>

                                <div className={'relative'}>
                                    <CountUp
                                        delay={0}
                                        start={userScore - questionScore}
                                        end={userScore}
                                        duration={1}
                                        onEnd={() => setShowScore(true)}
                                    >
                                        {({ countUpRef }) => (
                                            <span
                                                className={
                                                    'text-white relative text-6xl font-tilt'
                                                }
                                                ref={countUpRef}
                                            />
                                        )}
                                    </CountUp>
                                    {questionScore > 0 && showScore && (
                                        <span
                                            className={
                                                'font-tilt text-4xl text-xl absolute -right-16 -top-5 pl-8 rotate-12 text-green-500 transform-gpu'
                                            }
                                        >
                                            + {questionScore}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Separator />
                </div>

                <div
                    className={
                        'flex flex-row gap-2 items-baseline justify-between w-full px-8 pt-4 '
                    }
                >
                    <div className={'flex flex-col  items-center'}>
                        <h1 className={'text-xl opacity-75 '}>Rank</h1>
                        <p className={'text-white text-4xl'}>
                            #{calculateRank}
                        </p>
                        <h3 className={'ml-8 mb-4'}>
                            /{(top20ByScore?.length ?? 0) + 20}
                        </h3>
                    </div>
                    <div className={'text-center'}>
                        <h1 className={'text-xl opacity-75 '}>Correct</h1>
                        <span
                            className={'text-white relative text-4xl font-tilt'}
                        >
                            {' '}
                            {actualCorrectQuestions}
                        </span>
                        <h3 className={'ml-8 mb-4'}>/10</h3>
                    </div>
                </div>
                <div className={'fixed bottom-0 left-0 w-full px-4 pb-8'}>
                    <BaseButton
                        onClick={() => props.onConfirm()}
                        color={'yellow'}
                    >
                        {currentQuestion!.questionIndex + 1 === 10
                            ? t('see-results')
                            : t('next-question')}
                    </BaseButton>
                </div>
            </div>
        </div>
    )
}

export default QuestionScore
