import { IonPage } from '@ionic/react'
import { useHistory } from 'react-router'
import { useBoundStore } from '../../stores/store'
import { useTranslation } from 'react-i18next'
import UserAvatar from '../../components/atoms/UserAvatar'
import { useEffect } from 'react'
import { getUserAction } from '../../actions/auth/getUser.action'
import PageWrapper from '../../components/PageWrapper'

const OAuthScreen: React.FC = () => {
    const history = useHistory()
    const accessToken = useBoundStore((state) => state.accessToken)
    const login = useBoundStore((state) => state.login)
    const { t } = useTranslation()

    useEffect(() => {
        if (!accessToken?.length) return
        getUserAction(accessToken)
            .then((data) => {
                if (data.length) {
                    login({
                        ...data[0],
                        country: {
                            name: data[0].country_name,
                            code: data[0].country_code,
                        },
                    })
                    history.push('/home')
                } else {
                    history.push('/auth')
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }, [accessToken?.length])
    return (
        <IonPage>
            <PageWrapper>
                <div
                    className={
                        'flex flex-col w-full h-full items-center justify-center'
                    }
                >
                    <UserAvatar
                        avatarId={1}
                        additionalClasses={'w-20 h-20 animate-ping'}
                    />
                </div>
            </PageWrapper>
        </IonPage>
    )
}

export default OAuthScreen
