import createFetcher from './fetcher'
import useSWR from 'swr'

interface HighscoresResponse {
    highscores: any[]
    count: number
}

function useHighscores(
    accessToken: string,
    type: 'today' | 'week' | 'month' | 'all',
    pageIndex: number
) {
    const fetcher = createFetcher(accessToken!)
    const date = new Date().toISOString().split('T')[0]

    const { data, error, mutate, isLoading, isValidating } =
        useSWR<HighscoresResponse>(
            '/highscores?date=' + date + '&type=' + type + '&page=' + pageIndex,
            fetcher,
            {
                revalidateOnFocus: false,
            }
        )

    return {
        rankings: data?.highscores.map((h) => {
            return {
                country_code: h.country_code,
                username: h.username,
                games: h.games ?? 1,
                points: h.totalScore ?? h.totalscore,
                rank: h.rank,
            }
        }),
        total: data?.count,
        isLoading: isLoading && !isValidating,
        error: error,
        isValidating: isValidating,
        mutate,
    }
}

export default useHighscores
