import { FunctionComponent } from 'react'
import Header from '../../components/atoms/Header'
import { IonPage } from '@ionic/react'
import DailyQuizWizard from '../../components/DailyQuizWizard'
import { useBoundStore } from '../../stores/store'
import PageWrapper from '../../components/PageWrapper'

const DailyQuiz: FunctionComponent = () => {
    const currentQuestion = useBoundStore((state) => state.currentQuestion)

    return (
        <IonPage>
            <Header hideLogout={true}>
                {currentQuestion && (
                    <div
                        className={
                            'flex flex-col w-full h-full items-center justify-center'
                        }
                    >
                        <p
                            className={
                                'text-xl bg-gradient-to-r from-[#532B14] to-[#3C1500] text-transparent bg-clip-text '
                            }
                        >
                            Question
                        </p>
                        <h1 className={'text-xl'}>
                            {currentQuestion.questionIndex + 1}/10
                        </h1>
                    </div>
                )}
            </Header>
            <PageWrapper>
                <DailyQuizWizard />
            </PageWrapper>
        </IonPage>
    )
}

export default DailyQuiz
