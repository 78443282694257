import { FunctionComponent, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '../atoms/TextField'
import BaseButton from '../atoms/Button/BaseButton'
import { useSwiper } from 'swiper/react'
import SlideWrapper from '../atoms/Slides/SlideWrapper'
import { checkExistingUsername } from '../../actions/auth/checkUsername.action'
import { useBoundStore } from '../../stores/store'
import { toast } from 'react-toastify'

interface IProps {
  onConfirm: (username: string) => void
}

const AuthUsernameSlide: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()
  const [username, setUsername] = useState<string>()
  const swiper = useSwiper()
  const ref = useRef<HTMLInputElement | null>(null)
  const accessToken = useBoundStore((state) => state.accessToken)
  const [loading, setLoading] = useState(false)
  const verifyUsername = async () => {
    if (username) {
      try {
        setLoading(true)
        const { data, error } = await checkExistingUsername(
          accessToken!,
          username
        )
        if (error) {
          toast(error.message, { type: 'error' })
        } else {
          props.onConfirm(username)
          swiper.slideNext()
        }
      } catch (error) {
        console.log(error)
      }
      finally {
        setLoading(false)
      }
    }
  }

  return (
    <SlideWrapper className={'h-2/3'}>
      <div className={'flex flex-col items-center gap-8'}>
        <div className={'flex flex-col gap-4 items-center'}>
          <h1>{t('enter-nickname')}</h1>
          <h3 className={'text-center'}>
            {t('pick-a-dazzling-name')}
          </h3>
        </div>

        <TextField
          ref={ref}
          type={'text'}
          defaultValue={username}
          onChange={(typedUsername) => setUsername(typedUsername)}
          placeholder={'Nickname'}
        />

        <BaseButton
          onClick={verifyUsername}
          color={'yellow'}
          disabled={!username || username.length < 3}
          loading={loading}
        >
          {t('continue')}
        </BaseButton>
      </div>
    </SlideWrapper>
  )
}

export default AuthUsernameSlide
