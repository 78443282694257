import { FunctionComponent } from 'react'
import { useBoundStore } from '../../../stores/store'
import streakIcon from '../../../assets/icons/streak.png'
import useStreaks from '../../../hooks/useStreaks'
import Lottie from 'lottie-react'
import fireAnimation from '../../../assets/lotties/fire.json'

const StreaksCounter: FunctionComponent = () => {
    const accessToken = useBoundStore((state) => state.accessToken)
    const { streaks, isLoading, isError } = useStreaks(accessToken)

    return (
        <div className={'flex flex-row  items-center  '}>
            {isLoading && (
                <Lottie
                    className={'w-12 h-12 '}
                    animationData={fireAnimation}
                    loop={true}
                    width={32}
                    height={32}
                />
            )}
            {streaks !== undefined && (
                <>
                    <img
                        src={streakIcon}
                        className={'w-12 h-12'}
                        alt={'streaks'}
                    />
                    <p className={'streaks-counter text-2xl'}>
                        {streaks.streaks}
                    </p>
                </>
            )}
        </div>
    )
}

export default StreaksCounter
