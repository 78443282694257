import React, { useEffect, useState } from 'react'

interface CountDownBarProps {
    time: number
    onEnd: () => void

    showProgress: boolean
    pause: boolean
}

const CountDownBar: React.FC<CountDownBarProps> = ({
    time,
    onEnd,
    showProgress,
    pause,
}) => {
    const [width, setWidth] = useState(100)

    useEffect(() => {
        let remainingTime = time / 1000

        const interval = setInterval(() => {
            remainingTime -= 0.4
            if (showProgress && !pause) {
                const newWidth = (remainingTime / (time / 1000)) * 100
                setWidth(newWidth)
            }

            if (remainingTime <= 0) {
                clearInterval(interval) // Rimuovi l'intervallo
                onEnd() // Chiama la funzione onEnd
            }
        }, 400)

        return () => clearInterval(interval)
    }, [time, onEnd])

    return (
        <div className="w-full h-2 bg-black/50 relative rounded-full overflow-hidden">
            <div
                className="h-full countDown-bar absolute top-0 left-0 rounded-full transition-width duration-1000"
                style={{ width: `${width}%` }}
            ></div>
        </div>
    )
}

export default CountDownBar
