import { FunctionComponent, useMemo, useState } from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Pagination, Scrollbar } from 'swiper/modules'

import { Swiper, SwiperSlide } from 'swiper/react'
import AuthUsernameSlide from './AuthUsernameSlide'
import AuthCharacterSlide from './AuthCharacterSlide'
import { useBoundStore } from '../../stores/store'
import { TUser } from '../../types/user'
import AuthSlideNavigation from './AuthSlideNavigation'
import AuthCountrySlide from './AuthCountrySlide'

interface IProps {
    onSubmit: (data: TUser) => void
}

const AuthSlider: FunctionComponent<IProps> = (props) => {
    const [userData, setUserData] = useState<TUser>({
        username: '',
        age: 0,
        country: {
            code: '',
            name: '',
        },
        avatarId: 0,
    })
    const accessToken = useBoundStore((state) => state.accessToken)

    const canSubmit = useMemo(() => {
        return !!(userData?.username && userData?.country)
    }, [userData])

    const submitForm = () => {
        if (!userData) return
        props.onSubmit(userData)
    }

    return (
      <Swiper
        className={' w-full h-screen flex items-center justify-center  '}
        // install Swiper modules
        modules={[Pagination, Scrollbar]}
        slidesPerView={1}
        pagination={{ clickable: false }}
        allowTouchMove={false}
        onSwiper={(swiper) => console.log(swiper)}
      >
          <AuthSlideNavigation userData={userData} />

          <SwiperSlide
            className={'flex flex-row items-center justify-center'}
          >
              <AuthUsernameSlide
                onConfirm={(username: string) =>
                  setUserData({ ...userData, username })
                }
              />
          </SwiperSlide>
          <SwiperSlide
            className={'flex flex-row items-center justify-center'}
          >
              <AuthCountrySlide
                onConfirm={(country) =>
                  setUserData({ ...userData, country })
                }
              />
          </SwiperSlide>

          <SwiperSlide
            className={' flex flex-row items-center justify-center  '}
          >
              <AuthCharacterSlide
                canSubmit={canSubmit}
                onAvatarSelected={(avatarId) =>
                  setUserData({ ...userData, avatarId })
                }
                onConfirm={() => submitForm()}
              />
          </SwiperSlide>
      </Swiper>
    )
}

export default AuthSlider
