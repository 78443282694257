import { FunctionComponent } from 'react'
import { IonHeader, IonIcon, IonToolbar } from '@ionic/react'
import { logOut } from 'ionicons/icons'
import { useAuth } from '../../AuthContext'
import StreaksCounter from '../../Stats/StreaksCounter'

interface IProps {
    children?: React.ReactNode
    hideLogout?: boolean
}

const Header: FunctionComponent<IProps> = (props) => {
    const { signOut } = useAuth()

    return (
        <IonHeader
            className={
                'bg-black tab-bar-bg  p-2 header-shadow flex flex-row items-center justify-between '
            }
        >
            <IonToolbar className={'flex flex-row items-center flex-start'}>
                <div
                    slot={'start'}
                    className={
                        'flex flex-row  items-center w-full justify-between   '
                    }
                >
                    <StreaksCounter />
                    <div>{props.children}</div>
                    <div className="invisible bg-green-500">Primo</div>
                </div>
            </IonToolbar>

            {!props.hideLogout && (
                <IonIcon
                    className={'tab-icon  text-4xl  '}
                    icon={logOut}
                    onClick={signOut}
                />
            )}
        </IonHeader>
    )
}

export default Header
