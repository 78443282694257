import useSWR from 'swr'
import createFetcher from './fetcher'
import { MyStats } from '../types/stats'

const date = new Date().getTime()

function useUserStats(accessToken?: string) {
    if (!accessToken) throw new Error('No access token provided')
    const fetcher = createFetcher(accessToken)
    const { data, error, mutate } = useSWR<MyStats>(
        '/user-stats?date=' + date,
        fetcher,
        {
            revalidateOnFocus: true,
            revalidateOnReconnect: false,
            revalidateIfStale: true,
        }
    )

    return {
        userStats: data,
        isLoading: !error && !data,
        isError: error,
        refresh: mutate,
    }
}

export default useUserStats
