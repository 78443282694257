import { TUser } from '../types/user'
import { StateCreator } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { Session } from '@supabase/supabase-js'

interface UserState {
    user?: TUser
    loggedIn: boolean
    loading: boolean
    error?: string

    userOAuth?: Session

    accessToken?: string
}

interface UserActions {
    login: (user: TUser) => void

    setUserOAuth: (session: Session, token: string) => void
    setUserPlainAuth: (session: Session, token: string) => void
    logout: () => void
    setError: (error: string) => void
    clearError: () => void
}

export type UserSlice = UserState & UserActions

export const createUserSlice: StateCreator<
    UserSlice,
    [],
    [['zustand/immer', never]]
> = immer((set) => ({
    userToken: undefined,
    user: undefined,
    loggedIn: false,
    loading: false,
    error: undefined,
    accessToken: undefined,
    setUserPlainAuth: (session, token) =>
        set((state) => {
            state.userOAuth = session
            state.accessToken = token
        }),
    setUserOAuth: (session, token) =>
        set((state) => {
            state.userOAuth = session
            state.accessToken = token
        }),
    login: (user) =>
        set((state) => {
            state.user = user
            state.loggedIn = true
        }),

    logout: () =>
        set((state) => {
            state.user = undefined
            state.loggedIn = false
            state.accessToken = undefined
            state.userOAuth = undefined
        }),

    setError: (error) =>
        set((state) => {
            state.error = error
        }),

    clearError: () =>
        set((state) => {
            state.error = undefined
        }),
}))
