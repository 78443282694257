import { FunctionComponent } from 'react'
import CountryFlag from '../CountryFlag'

interface IProps {
    index: number
    country_code: string
    username: string
    games: number
    points: number
}

const ComposableLeaderboardItem: FunctionComponent<IProps> = (props) => {
    return (
        <div
            className={
                'flex flex-row text-white bg-transparent justify-between w-full items-center font-tilt text-xl '
            }
        >
            <span>{props.index}</span>
            <div className={'flex flex-row items-center justify-between gap-2'}>
                <span>
                    <CountryFlag
                        width={'w-8'}
                        height={'h-8'}
                        country={{ flagId: props.country_code }}
                    />
                </span>
                <span className={'truncate w-28 overflow-hidden'}>
                    {props.username}
                </span>
            </div>
            <span>{props.games}</span>
            <span>{props.points}</span>
        </div>
    )
}

export default ComposableLeaderboardItem
