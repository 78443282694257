import { FunctionComponent } from 'react'
import { AverageUserStats } from '../../types/stats'

interface IProps {
    userStats: AverageUserStats
}

const AverageUserStatsCards: FunctionComponent<IProps> = (props) => {
    return (
        <div className={'flex flex-col w-full gap-4'}>
            <div
                className={
                    'flex flex-row py-2 justify-around items-center stats-card'
                }
            >
                <div
                    className={
                        'text-white flex flex-col text-center w-1/2 border-r'
                    }
                >
                    <h2 className={'text-3xl text-yellow-200'}>
                        {Math.floor(props.userStats.average_score)}{' '}
                    </h2>
                    <h3 className={'text-center'}>
                        Average <br /> score
                    </h3>
                </div>
                <div className={'text-white flex flex-col  text-center w-1/2'}>
                    <h2 className={'text-3xl font-light text-yellow-200'}>
                        {(
                            (props.userStats.total_correct /
                                props.userStats.total_questions) *
                            10
                        ).toFixed(2)}
                        /10
                    </h2>
                    <h3>
                        Average <br /> correct
                    </h3>
                </div>
            </div>
            <div className={'grid grid-cols-2 gap-4  px-8'}>
                <div
                    className={
                        'flex flex-col justify-center items-center text-center rounded-2xl py-2  stats-card'
                    }
                >
                    <h2 className={'text-3xl text-white'}>
                        {Math.floor(props.userStats.best_score)}
                    </h2>
                    <h3 className={'text-gray-400'}>
                        Best <br /> score
                    </h3>
                </div>
                <div
                    className={
                        'flex flex-col justify-center items-center text-center rounded-2xl py-2 stats-card'
                    }
                >
                    <h2 className={'text-3xl text-white'}>
                        {(props.userStats.average_speed / 1000).toFixed(2)}s
                    </h2>
                    <h3 className={'text-gray-400'}>
                        Average <br /> speed
                    </h3>
                </div>
                <div
                    className={
                        'flex flex-col justify-center items-center text-center rounded-2xl py-2  stats-card'
                    }
                >
                    <h2 className={'text-3xl text-white'}>
                        {props.userStats.total_questions}
                    </h2>
                    <h3 className={'text-gray-400'}>
                        Total <br /> Questions
                    </h3>
                </div>
                <div
                    className={
                        'flex flex-col justify-center items-center text-center rounded-2xl py-2  stats-card'
                    }
                >
                    <h2 className={'text-3xl text-white'}>
                        {props.userStats.total_correct}
                    </h2>
                    <h3 className={'text-gray-400'}>
                        Total <br /> Correct
                    </h3>
                </div>
            </div>
        </div>
    )
}

export default AverageUserStatsCards
