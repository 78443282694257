import useSWR from 'swr'
import createFetcher from './fetcher'
import { FetchDailyQuizDataResults } from '../actions/quiz/fetchDailyQuiz.action'

interface StatsResponse {
    choicesAnalytics: FetchDailyQuizDataResults['choicePercentage']
    totalScoreRanking: FetchDailyQuizDataResults['top20ByScore']
    questionsScoreRanking: FetchDailyQuizDataResults['questionsScoreByUser']
    questionsTimeToTapRanking: FetchDailyQuizDataResults['questionsTimeSpent']
}

function useDailyQuizStats(dailyQuizId?: string, accessToken?: string) {
    const fetcher = createFetcher(accessToken!)
    const shouldFetch = accessToken && accessToken.trim() !== '' && dailyQuizId
    const { data, error, mutate } = useSWR<StatsResponse>(
        shouldFetch ? '/get-daily-quiz-stats?dailyQuizId=' + dailyQuizId : null,
        fetcher,
        {
            revalidateOnFocus: true,
            revalidateOnMount: true,
        }
    )

    return {
        dailyQuizStats: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export default useDailyQuizStats
