import { FunctionComponent } from 'react'

interface CountryFlagProps {
    country: {
        flagId: string
    }

    width?: string
    height?: string
}

const CountryFlag: FunctionComponent<CountryFlagProps> = ({
    country,
    width,
    height,
}) => {
    return (
        <div
            className={
                `fib fi-${country.flagId.toLowerCase()}   rounded-full ` +
                (width ?? 'w-12 ') +
                ' ' +
                (height ?? ' h-12')
            }
        ></div>
    )
}

export default CountryFlag
