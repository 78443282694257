import { FunctionComponent } from 'react'
import { useBoundStore } from '../../../stores/store'
import UserAvatar from '../UserAvatar'
import useUserRank from '../../../hooks/useUserRank'
import { IonSpinner } from '@ionic/react'

interface IProps {
    type: 'today' | 'week' | 'month' | 'all'
}

const UserComposableRanking: FunctionComponent<IProps> = (props) => {
    const user = useBoundStore((state) => state.user)
    const accessToken = useBoundStore((state) => state.accessToken)
    const { rank, totalScore, isLoading, error } = useUserRank(
        accessToken!,
        props.type
    )

    if (!user) return
    if (isLoading || rank === null)
        return (
            <div className={'flex items-center justify-center'}>
                <IonSpinner />
            </div>
        )

    return (
        <div
            className={
                'w-full  flex flex-row justify-between items-center pl-4  pr-8'
            }
        >
            <div className={'flex flex-row  items-center justify-between'}>
                <UserAvatar
                    avatarId={user.avatarId}
                    additionalClasses={'w-20'}
                />
                <div className={'flex flex-col justify-center '}>
                    <p className={'text-2xl text-white'}>{user.username}</p>
                    <p className={' text-xl text-white'}>#{rank}</p>
                </div>
            </div>
            <p className={'text-white text-3xl'}>{totalScore}</p>
        </div>
    )
}

export default UserComposableRanking
