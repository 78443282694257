import { FunctionComponent, useRef, useState } from 'react'
import BaseButton from '../../atoms/Button/BaseButton'
import BaseDialog, { DialogRef } from '../../atoms/Dialog'
import LoginForm from './LoginForm'
import SignupForm from './SignupForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import BlockingMessage from '../../BlockingMessage'

const PlainAuth: FunctionComponent = () => {
    const dialogRef = useRef<DialogRef>(null)
    const { t } = useTranslation()
    const [isSignup, setIsSignup] = useState(false)
    const [isForgotPassword, setIsForgotPassword] = useState(false)
    const [isMailVerificationView, setIsMailVerificationView] = useState(false)
    const openDialog = () => {
        dialogRef.current?.openDialog()
    }

    const onClosedDialog = () => {
        setIsSignup(false)
        setIsForgotPassword(false)
        setIsMailVerificationView(false)
    }

    const onSignupSuccess = () => {
        dialogRef.current?.closeDialog()
        toast(
            'Signup successful! Please check your email to verify your account.',
            { type: 'success' }
        )
        setIsSignup(false)
        setIsForgotPassword(false)
        setIsMailVerificationView(true)
        dialogRef.current?.openDialog()
    }

    const onLoginSuccess = () => {
        console.log('SUCCESS')
        dialogRef.current?.closeDialog()
    }

    const onResetPasswordSuccess = () => {
        dialogRef.current?.closeDialog()
        toast('Password reset email sent! Please check your email.', {
            type: 'success',
        })
    }

    const onResetPasswordError = () => {
        toast('Password reset failed! Please try again.', { type: 'error' })
    }

    const onLoginError = (message: string) => {
        toast(message, { type: 'error' })
    }

    const onSignupError = (error: string) => {
        toast(error, { type: 'error' })
    }
    return (
        <>
            <BaseDialog
                ref={dialogRef}
                headerText={'Header'}
                onClosed={onClosedDialog}
            >
                {!isSignup && !isForgotPassword && !isMailVerificationView && (
                    <LoginForm
                        onLoginSuccess={onLoginSuccess}
                        onLoginError={onLoginError}
                        onForgotPassword={() => setIsForgotPassword(true)}
                        onSignUp={() => setIsSignup(true)}
                    />
                )}
                {isSignup && (
                    <SignupForm
                        onSuccess={onSignupSuccess}
                        onError={onSignupError}
                        onLoginClick={() => {
                            setIsSignup(false)
                            setIsForgotPassword(false)
                        }}
                    />
                )}
                {isForgotPassword && (
                    <ForgotPasswordForm
                        onSuccess={onResetPasswordSuccess}
                        onError={onResetPasswordError}
                    />
                )}
                {isMailVerificationView && (
                    <BlockingMessage>
                        <h5 className={'text-xl text-center'}>
                            {t('mail-verification-check')}
                        </h5>
                        <h5 className={'text-xl text-center'}>
                            {t('remember-check-your-spam')}
                        </h5>
                    </BlockingMessage>
                )}
            </BaseDialog>
            <BaseButton
                onClick={openDialog}
                color={'yellow'}
                additionalClasses={'w-4/5'}
            >
                {t('continue-with-email')}
            </BaseButton>
        </>
    )
}

export default PlainAuth
