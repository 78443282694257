import { FunctionComponent, ReactNode } from 'react'
import { Redirect } from 'react-router'
import HomePage from '../../pages/Home'
import { Route } from 'react-router-dom'
import { useBoundStore } from '../../stores/store'

interface IProps {
  path:string

  component:JSX.Element
}
const AuthGateRoute:FunctionComponent<IProps> = (authProps) => {
  const user = useBoundStore((state) => state.user)
  const accessToken = useBoundStore((state) => state.accessToken)

  const renderFunction = (props:any) => {
    if (!accessToken) {
      return <Redirect to={'/providerAuth'} />
    }
    if (!user) {
      return <Redirect to={'/auth'} />
    }
    return authProps.component
  }

  return <Route path={authProps.path} render={renderFunction} />
}

export default AuthGateRoute
