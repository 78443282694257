import { IonContent } from '@ionic/react'
import { FC, ReactNode } from 'react'
import mainBg from '../../assets/backgrounds/main-bg.png'

interface IProps {
    children: ReactNode
}

const PageWrapper: FC<IProps> = ({ children }) => {
    return (
        <IonContent>
            <div
                style={{
                    backgroundImage: `url(${mainBg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
                className={'h-full '}
            >
                {children}
            </div>
        </IonContent>
    )
}

export default PageWrapper
