import { FunctionComponent } from 'react'
import { MyStats } from '../../types/stats'
import { orderWeeklyUserResults } from '../../utils/orderWeeklyUserResults'
import {
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts'

interface IProps {
    userResults: MyStats['userResults']
}

const UserWeeklyResultsChart: FunctionComponent<IProps> = (props) => {
    const data = orderWeeklyUserResults(props.userResults)
    const maxValue = Math.max(...data.map((item) => item.totalScore))

    return (
        <div className={'w-full stats-card py-4'}>
            <ResponsiveContainer width={'100%'} height={200}>
                <LineChart
                    data={data}
                    margin={{ top: 5, right: 20, bottom: 10, left: 0 }}
                >
                    <XAxis
                        padding={{ left: 30, right: 50 }}
                        tick={({ x, y, payload }) => {
                            const associatedData = data.find(
                                (item) => item.name === payload.value
                            )
                            // if the name of today with first two letters is equal to payload.value then fill is red, else white

                            const isToday =
                                new Date().toDateString().slice(0, 2) ===
                                payload.value.slice(0, 2)

                            if (associatedData?.totalScore === 0) {
                                return (
                                    <>
                                        <foreignObject
                                            x={x - 20}
                                            y={y + 8}
                                            width={24}
                                            height={24}
                                        >
                                            <img
                                                src={'/times.svg'}
                                                alt={'icon'}
                                            />
                                        </foreignObject>
                                        <text
                                            x={x}
                                            y={y}
                                            fill={'#FFFFFF'}
                                            textAnchor="end"
                                            fontSize={10}
                                            style={{
                                                fontFamily: 'Tilt Neon',
                                                fontSize: 16,
                                                opacity: isToday ? 1 : 0.5,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {payload.value}
                                        </text>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <foreignObject
                                            x={x - 20}
                                            y={y + 8}
                                            width={24}
                                            height={24}
                                        >
                                            <img
                                                src={'/check.svg'}
                                                alt={'icon'}
                                            />
                                        </foreignObject>
                                        <text
                                            x={x}
                                            y={y}
                                            fill={'#FFFFFF'}
                                            textAnchor="end"
                                            fontSize={10}
                                            style={{
                                                fontFamily: 'Tilt Neon',
                                                fontSize: 16,
                                                opacity: isToday ? 1 : 0.5,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {payload.value}
                                        </text>
                                    </>
                                )
                            }
                        }}
                        dataKey="name"
                        axisLine={false}
                        tickLine={false}
                    />

                    <YAxis
                        domain={[0, maxValue]}
                        padding={{ bottom: 40, top: 20 }}
                        tick={({ x, y, payload }) => {
                            if (payload.value === 0) {
                                return (
                                    <text
                                        x={x}
                                        y={y}
                                        dy={-4}
                                        fill="#FFFFFF"
                                        textAnchor="end"
                                        fontSize={10}
                                        style={{
                                            fontFamily: 'Tilt Neon',
                                            fontSize: 16,
                                        }}
                                    >
                                        {payload.value}
                                    </text>
                                )
                            } else if (payload.value === maxValue) {
                                return (
                                    <text
                                        x={x}
                                        y={y}
                                        dy={-4}
                                        fill="#FFFFFF"
                                        textAnchor="end"
                                        fontSize={10}
                                        style={{
                                            fontFamily: 'Tilt Neon',
                                            fontSize: 16,
                                        }}
                                    >
                                        {payload.value}
                                    </text>
                                )
                            } else {
                                return (
                                    <text
                                        x={x}
                                        y={y}
                                        dy={-4}
                                        fill="#FFFFFF"
                                        textAnchor="end"
                                        fontSize={10}
                                        style={{
                                            fontFamily: 'Tilt Neon',
                                            fontSize: 16,
                                        }}
                                    ></text>
                                )
                            }
                        }}
                        dataKey={'totalScore'}
                        axisLine={false}
                        tickLine={false}
                    />
                    <Line
                        key={'totalScore-' + Math.random() * 100}
                        connectNulls={true}
                        strokeWidth={3}
                        type="linear"
                        dataKey="totalScore"
                        stroke="#27E202"
                        activeDot={{ r: 20 }}
                        dot={(props) => {
                            const { cx, cy, value } = props

                            const fill = value === 0 ? '#FF0000' : '#27E202'

                            return <circle cx={cx} cy={cy} r={6} fill={fill} />
                        }}
                    />

                    <Tooltip />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default UserWeeklyResultsChart
