import { FetchDailyQuizDataResults } from '../quiz/fetchDailyQuiz.action'
import { FeedbackAnswers } from '../../types/feedback'

interface FetchUserFeedbackResults {
    answers: FeedbackAnswers[]
    feedbackId: number
    userId: number
}

export const fetchUserFeedback: (
    accessToken: string,
    feedbackId: number
) => Promise<FetchDailyQuizDataResults> = async (accessToken, feedbackId) => {
    const fetchFeedbackRequest = await fetch(
        process.env.REACT_APP_EDGE_FUNCTIONS_URI +
            '/user-feedback?feedbackId=' +
            feedbackId,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }
    )

    const feedbackData = await fetchFeedbackRequest.json()

    return feedbackData.data[0]
}
