import { FunctionComponent, useState } from 'react'
import { QuizChoice as TQuizChoice } from '../../../types/quiz'
import QuizChoice from '../../atoms/QuizChoice'

interface IProps {
    choices: TQuizChoice[]
    onChoice: (choiceId: string) => void
    showChoicesStats: boolean
    showAnswersOutcome: boolean | undefined
}

const QuizChoiceList: FunctionComponent<IProps> = (props) => {
    const [userChoiceId, setUserChoiceId] = useState<string | null>(null)

    const onChoiceMade = async (choiceId: string) => {
        setUserChoiceId(choiceId)
        props.onChoice(choiceId)
    }

    return (
        <div
            className={
                'flex flex-col items-center justify-center gap-4 w-full '
            }
        >
            {props.choices.map((choice) => (
                <QuizChoice
                    showUserAvatar={choice.id === userChoiceId}
                    key={choice.id}
                    choice={choice}
                    disabled={!!userChoiceId}
                    onClick={(choiceId) => onChoiceMade(choiceId)}
                    showStats={props.showChoicesStats}
                    userChoiceId={userChoiceId}
                    isGivenAnswerWrong={
                        props.showAnswersOutcome &&
                        choice.id === userChoiceId &&
                        !choice.isCorrect
                    }
                    isAnswerCorrect={
                        props.showAnswersOutcome && choice.isCorrect
                    }
                />
            ))}
        </div>
    )
}

export default QuizChoiceList
