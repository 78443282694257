import { FunctionComponent } from 'react'
import Header from '../../components/atoms/Header'
import { IonPage } from '@ionic/react'
import { useBoundStore } from '../../stores/store'
import AverageUserStatsCards from '../../components/Stats/AverageUserStats'
import UserWeeklyResultsChart from '../../components/Stats/UserWeeklyResultsChart'
import { useTranslation } from 'react-i18next'
import Loading from '../../components/atoms/Loading'
import useUserStats from '../../hooks/useUserStats'
import PageWrapper from '../../components/PageWrapper'

const UserStats: FunctionComponent = () => {
    const { t } = useTranslation()
    const accessToken = useBoundStore((state) => state.accessToken)
    const { userStats, isLoading, isError } = useUserStats(accessToken)

    if (isError) {
        return (
            <IonPage>
                <Header />
                <PageWrapper>
                    <div
                        className={
                            'w-full h-full flex flex-col items-center justify-center'
                        }
                    >
                        <h1 className={'text-center text-2xl py-4 '}>
                            {t('error-occurred')}
                        </h1>
                    </div>
                </PageWrapper>
            </IonPage>
        )
    }

    return (
        <IonPage>
            <Header />
            <PageWrapper>
                {isLoading && <Loading />}
                {!userStats?.averageUserStats && (
                    <div
                        className={
                            'w-full h-full flex flex-col items-center justify-center'
                        }
                    >
                        <h1 className={'text-center text-2xl py-4 '}>
                            {t('no-stats')}
                        </h1>
                    </div>
                )}
                {userStats && userStats.averageUserStats && (
                    <>
                        <h1 className={'text-center text-2xl py-4 '}>
                            {t('my-stats')}
                        </h1>
                        <div className={'flex flex-col gap-4'}>
                            <UserWeeklyResultsChart
                                userResults={userStats.userResults}
                            />
                            <AverageUserStatsCards
                                userStats={userStats.averageUserStats}
                            />
                        </div>
                    </>
                )}
            </PageWrapper>
        </IonPage>
    )
}

export default UserStats
