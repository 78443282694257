import { FunctionComponent } from 'react'
import { motion } from 'framer-motion'
import logo from '../../../assets/app-logo.png'

interface IProps {
    additionalStyle?: React.CSSProperties
}

const Logo: FunctionComponent<IProps> = ({ additionalStyle }) => {
    return (
        <motion.div
            initial={{ opacity: 0.9, filter: 'brightness(0.9)' }}
            animate={{ opacity: 1, filter: 'brightness(1)' }}
            transition={{
                duration: 1,
                repeat: 1,
                repeatType: 'mirror',
                type: 'spring',
                bounce: 1,
            }}
        >
            <img
                src={logo}
                className={'w-4/5  mx-auto '}
                style={additionalStyle}
            />
        </motion.div>
    )
}

export default Logo
