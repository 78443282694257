import { FunctionComponent } from 'react'
import { IonPage } from '@ionic/react'
import GoogleAuth from './GoogleAuth'
import { useBoundStore } from '../../stores/store'
import { useHistory } from 'react-router'
import Logo from '../../components/atoms/Logo'
import PlainAuth from '../../components/Auth/PlainAuth'
import { Session } from '@supabase/supabase-js'
import PageWrapper from '../../components/PageWrapper'

const ProviderAuth: FunctionComponent = () => {
    const setUserOAuth = useBoundStore((state) => state.setUserOAuth)
    const history = useHistory()

    const authenticateUser = (user: Session, token: string) => {
        setUserOAuth(user, token)
        history.push('/auth')
    }
    return (
        <IonPage>
            <PageWrapper>
                <div
                    className={
                        'h-full flex flex-col justify-center items-center relative'
                    }
                >
                    <div
                        id={'auth-container'}
                        className={
                            '  flex flex-col  justify-center items-center gap-12   px-8  '
                        }
                    >
                        <Logo additionalStyle={{ marginTop: '-60px' }} />
                        <div className={'flex flex-col gap-5'}>
                            <GoogleAuth onAuth={authenticateUser} />
                            <PlainAuth />
                        </div>
                    </div>
                </div>
            </PageWrapper>
        </IonPage>
    )
}

export default ProviderAuth
