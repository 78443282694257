import { useSwiper } from 'swiper/react'
import { TUser } from '../../types/user'
import { FunctionComponent, useEffect, useState } from 'react'
import arrow from '../../assets/icons/arrow.svg'

interface IProps {
    userData: TUser
}

const AuthSlideNavigation: FunctionComponent<IProps> = ({ userData }) => {
    const swiper = useSwiper()
    const [activeIndex, setActiveIndex] = useState(0)
    const dataOrder: (keyof TUser)[] = ['username', 'age', 'country', 'avatarId']
    const [canGoForward, setCanGoForward] = useState(false)
    useEffect(() => {
        switch (dataOrder[activeIndex]) {
            case 'username':
                setCanGoForward(!!userData.username)
                break
            case 'age':
                setCanGoForward(false)
                break
            case 'country':
                setCanGoForward(


                        !!userData.country &&
                        userData.country.name !== ''
                )
                break
            case 'avatarId':
                setCanGoForward(false)
                break
            default:
                console.log('No such slide')
        }
    }, [activeIndex])

    useEffect(() => {
        const setAIndex = () => {
            setActiveIndex(swiper.activeIndex)
        }
        swiper.on('slideChange', setAIndex)

        return () => {
            swiper.off('slideChange', setAIndex)
        }
    }, [swiper])

    const canGoBack = activeIndex > 0

    console.log('Can go forward', canGoForward, userData)
    return (
        <div className={'absolute z-10 top-5  w-full h-12 '}>
            {canGoBack && (
                <img
                    className={'absolute top-3 left-2'}
                    src={arrow}
                    onClick={() => swiper.slidePrev()}
                />
            )}

            {canGoForward && (
                <img
                    src={arrow}
                    className={'transform rotate-180 absolute top-0 right-2'}
                    onClick={() => swiper.slideNext()}
                />
            )}
        </div>
    )
}

export default AuthSlideNavigation
