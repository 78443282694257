import { FunctionComponent, useState } from 'react'
import { IonPage } from '@ionic/react'
import AuthSlider from '../../components/AuthSlider'
import { useBoundStore } from '../../stores/store'
import { useHistory } from 'react-router'

import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { signupUserAction } from '../../actions/auth/signupUser.action'
import { TUser } from '../../types/user'
import PageWrapper from '../../components/PageWrapper'

const AuthPage: FunctionComponent = () => {
    const login = useBoundStore((state) => state.login)
    const history = useHistory()
    const { t } = useTranslation()
    const accessToken = useBoundStore((state) => state.accessToken)
    const [isUserVerified, setIsUserVerified] = useState(false)
    const onSubmit = async (userData: TUser) => {
        try {
            await signupUserAction(
                {
                    username: userData.username,
                    age: userData.age ?? undefined,
                    city: userData?.city?.name,
                    country: userData.country,
                    avatarId: userData.avatarId,
                },
                accessToken!
            )
            setIsUserVerified(true)
            toast(t('toast-user-created'))
        } catch (e) {
            toast(t('toast-user-creation-failed'))
            console.error(e)
        }

        /*   login(data)
history.push('/home')*/
    }

    if (isUserVerified) {
        return (
            <IonPage>
                <PageWrapper>
                    <div
                        className={
                            'w-full h-full flex items-center justify-center'
                        }
                    >
                        <h1 className={'text-2xl font-bold'}>
                            Your account has been verified! Please come back to
                            the mobile app to continue.
                        </h1>
                    </div>
                </PageWrapper>
            </IonPage>
        )
    }
    return (
        <IonPage>
            <PageWrapper>
                <div
                    className={'w-full h-full flex items-center justify-center'}
                >
                    <AuthSlider onSubmit={onSubmit} />
                </div>
            </PageWrapper>
        </IonPage>
    )
}

export default AuthPage
