import { FunctionComponent, useEffect, useMemo } from 'react'
import { QuizChoice as IQuizChoice } from '../../../types/quiz'
import UserAvatar from '../UserAvatar'
import { motion } from 'framer-motion'
import { useBoundStore } from '../../../stores/store'

interface IProps {
    choice: IQuizChoice
    onClick: (choiceId: string) => void

    isAnswerCorrect?: boolean

    disabled?: boolean

    showUserAvatar?: boolean

    showStats: boolean

    userChoiceId?: string | null

    isGivenAnswerWrong?: boolean
}

const QuizChoice: FunctionComponent<IProps> = (props) => {
    const userAvatar = useBoundStore((state) => state.user?.avatarId)
    let choicesPercentage = useBoundStore((state) => state.choicePercentage)
    const top20ByScore = useBoundStore((state) => state.top20ByScore)

    const calculatePercentage = (usersWhoChose: number, totalUsers: number) => {
        const percentage = Math.round((usersWhoChose / totalUsers) * 100)

        return isNaN(percentage) ? 0 : percentage
    }

    const randomNumberRef = useMemo(
        () => Math.round(Math.random() * 99) + 1,
        []
    )
    const choiceBackground =
        props.isAnswerCorrect === undefined
            ? 'from-white to-[#D9D9D9]'
            : props.isAnswerCorrect
              ? 'from-[#B5FF18] to-[#7AD41F]'
              : props.isGivenAnswerWrong
                ? 'from-red-500 to-red-600'
                : 'from-white to-[#D9D9D9]'

    const handleChoiceClick = (choiceId: string) => {
        if (!props.disabled) {
            props.onClick(choiceId)
        }
    }

    useEffect(() => {
        if (props.isAnswerCorrect && props.userChoiceId === props.choice.id) {
        } else if (
            !props.isAnswerCorrect &&
            props.userChoiceId === props.choice.id
        ) {
        }
    }, [props.isAnswerCorrect])
    return (
        <motion.div
            onClick={() => handleChoiceClick(props.choice.id)}
            className={
                'rounded-full  py-4 px-6 w-full quiz-choice-shadow bg-gradient-to-b    shadow-inner cursor-pointer relative ' +
                choiceBackground
            }
            {...(!props.disabled && {
                whileTap: {
                    scale: 0.95,
                    x: [0, -5, 5, -5, 5, 0],
                    rotate: [-2, 2, -2, 2, -2, 0],
                    transition: { duration: 0.05 },
                },
            })}
        >
            {props.showUserAvatar && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                >
                    <UserAvatar
                        avatarId={userAvatar!}
                        additionalClasses={
                            'w-12 h-12 absolute -top-6 right-0 transition-opacity ease-in duration-700 opacity-100'
                        }
                    />
                </motion.div>
            )}

            <div
                className={'flex  flex-row justify-between items-center w-full'}
            >
                <p className={'text-2xl text-gray-700'}>{props.choice.text}</p>

                {props.showStats && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1 }}
                        className={'text-xl text-gray-400'}
                    >
                        {calculatePercentage(
                            choicesPercentage?.choices[props.choice.id] ?? 0,
                            choicesPercentage?.users ?? 0
                        )}
                        %
                    </motion.div>
                )}
            </div>
        </motion.div>
    )
}

export default QuizChoice
