// create an object with 20 keys, each key is a string representing a username and the value is an object with the following keys: timeSpent, with a math.random time spent between 1 and 5, and a country code
const mockUsers = {
    tuba25: {
        timeSpent: 3283,
        score: 545,
        totalScore: 3211,
        country_code: 'US',
    },
    trumpetteakiwidesert: {
        timeSpent: 2246,
        score: 515,
        totalScore: 695,
        country_code: 'GB',
    },
    puresmokefindingnemo: {
        timeSpent: 3304,
        score: 358,
        totalScore: 5703,
        country_code: 'FR',
    },
    downfallngc1569otter: {
        timeSpent: 2908,
        score: 804,
        totalScore: 5273,
        country_code: 'GB',
    },
    bearneodroughthotdog: {
        timeSpent: 3474,
        score: 137,
        totalScore: 4195,
        country_code: 'IT',
    },
    incendiesbeescarface: {
        timeSpent: 736,
        score: 214,
        totalScore: 2684,
        country_code: 'ES',
    },
    sunisland: {
        timeSpent: 1745,
        score: 693,
        totalScore: 559,
        country_code: 'CN',
    },
    cartwheel: {
        timeSpent: 1730,
        score: 963,
        totalScore: 6954,
        country_code: 'BR',
    },
    ricedeneb: {
        timeSpent: 1544,
        score: 274,
        totalScore: 3017,
        country_code: 'AU',
    },
    whirlpool: {
        timeSpent: 1883,
        score: 985,
        totalScore: 3188,
        country_code: 'NL',
    },
    ariesroll: {
        timeSpent: 1088,
        score: 760,
        totalScore: 1976,
        country_code: 'IN',
    },
    jamesbond: {
        timeSpent: 3138,
        score: 892,
        totalScore: 3006,
        country_code: 'FR',
    },
    rollshorn: {
        timeSpent: 3384,
        score: 520,
        totalScore: 3160,
        country_code: 'MX',
    },
    gaganyaan: {
        timeSpent: 2769,
        score: 509,
        totalScore: 3677,
        country_code: 'ZA',
    },
    juicefern: {
        timeSpent: 896,
        score: 229,
        totalScore: 6640,
        country_code: 'MX',
    },
    pigshrimp: {
        timeSpent: 2164,
        score: 448,
        totalScore: 760,
        country_code: 'US',
    },
    hotdogtea: {
        timeSpent: 2947,
        score: 465,
        totalScore: 5204,
        country_code: 'BR',
    },
    antrocket: {
        timeSpent: 1882,
        score: 215,
        totalScore: 1195,
        country_code: 'IT',
    },
    pumbaasea: {
        timeSpent: 2583,
        score: 244,
        totalScore: 6684,
        country_code: 'US',
    },
}
export type QuestionStats = Record<
    string,
    {
        timeSpent?: number
        country_code: string
        score?: number
        totalScore?: number
    }
>

function deleteNObjects(obj, n) {
    const keys = Object.keys(obj)

    const keysToDelete = keys.slice(0, n)

    keysToDelete.forEach((key) => {
        delete obj[key]
    })

    return obj
}

export const calculateScoreQuestionLeaderboard = (
    userStats: QuestionStats,
    stats?: QuestionStats
) => {
    let finalLeaderboard = {}
    const totalUsers = stats ? Object.keys(stats).length : 0
    const usersToAdd = 20 - totalUsers
    console.log('usersToAdd', usersToAdd)
    const mockUsersAsArray = Object.entries(mockUsers)
        .map((user) => {
            return {
                [user[0]]: user[1],
            }
        })
        .slice(0, usersToAdd)
        .reduce((acc, curr) => {
            return { ...acc, ...curr }
        }, {})

    finalLeaderboard = { ...stats, ...userStats }
    if (usersToAdd > 0) {
        finalLeaderboard = { ...finalLeaderboard, ...mockUsersAsArray }
    }
    // sort the finalLeaderboard by timeSpent and return it
    const finalLeaderboardArray = Object.entries(finalLeaderboard)
        .map(([username, stats]) => {
            return {
                username,
                score: stats.score,
                country_code: stats.country_code.toLowerCase(),
            }
        })
        .sort((a, b) => b.score - a.score)
    return finalLeaderboardArray
}

export const calculateTotalScoreQuestionLeaderboard = (
    userStats?: { totalScore: number; username: string; country_code: string },
    stats?: { totalScore: number; username: string; country_code: string }[]
) => {
    let finalLeaderboard = []

    const totalUsers = stats ? stats.length : 0
    const usersToAdd = 20 - totalUsers
    const usersToAddArray = Object.keys(mockUsers).slice(0, usersToAdd)
    const mockUsersAsArray = Object.entries(mockUsers).map(
        ([username, stats]) => {
            return {
                username,
                totalScore: stats.totalScore,
                country_code: stats.country_code.toLowerCase(),
            }
        }
    )

    finalLeaderboard = [...(stats || [])]
    if (usersToAdd > 0) {
        finalLeaderboard = [...finalLeaderboard, ...mockUsersAsArray]
    }
    if (
        userStats &&
        !finalLeaderboard.find((user) => user.username === userStats.username)
    ) {
        finalLeaderboard.push(userStats)
    }

    return finalLeaderboard.sort((a, b) => b.totalScore - a.totalScore)
}

export const calculateTimeToTheTapQuestionLeaderboard = (
    currentUserResults?: QuestionStats,
    stats?: QuestionStats
) => {
    let finalLeaderboard = {}
    /*
     * calculate how many users we have in stats, then merge the mockUsers with the stats object with the following logic:
     * if stats has 1 user, then just 19 mocksUsers will be added to the finalLeaderboard
     * if stats has 2 users, then just 18 mocksUsers will be added to the finalLeaderboard
     * if stats has 3 users, then just 17 mocksUsers will be added to the finalLeaderboard
     * and so on, the final leaderboard should be always 20 users long with sorted time spent
     * */
    const totalUsers = stats ? Object.keys(stats).length : 0
    const usersToAdd = 19 - totalUsers
    const usersToAddArray = Object.keys(mockUsers).slice(0, usersToAdd)
    finalLeaderboard = { ...stats, ...mockUsers, ...currentUserResults }

    // sort the finalLeaderboard by timeSpent and return it

    const finalLeaderboardArray = Object.entries(finalLeaderboard)
        .map(([username, stats]) => {
            return {
                username,
                timeSpent: stats.timeSpent / 1000,
                country_code: stats.country_code.toLowerCase(),
            }
        })
        .sort((a, b) => a.timeSpent - b.timeSpent)
    return finalLeaderboardArray
}

export const calculateQuestionRank = (
    userStats: QuestionStats,
    stats?: QuestionStats
) => {
    const startingLeaderboard = calculateScoreQuestionLeaderboard(
        userStats,
        stats
    )

    return (
        startingLeaderboard.findIndex(
            (user) => user.username === Object.keys(userStats)[0]
        ) + 1
    )
}
export const calculateUserRank = (
    actualUserScore: {
        totalScore: number
        username: string
        country_code: string
    },
    leaderboard?: {
        totalScore: number
        username: string
        country_code: string
    }[]
) => {
    const startingLeaderboard = calculateTotalScoreQuestionLeaderboard(
        actualUserScore,
        leaderboard!
    )

    let rank = 1
    if (!startingLeaderboard?.length) {
        return rank
    }

    for (let user of startingLeaderboard) {
        if (user.totalScore > actualUserScore.totalScore) {
            rank++
        } else {
            return rank
        }
    }

    return rank
}
