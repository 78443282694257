import { FunctionComponent, useState } from 'react'
import TextField from '../../atoms/TextField'
import Logo from '../../atoms/Logo'
import BaseButton from '../../atoms/Button/BaseButton'
import { useAuth } from '../../AuthContext'

interface IProps {
    onSuccess: () => void
    onError: (message: string) => void
}

const PasswordRecoveryForm: FunctionComponent<IProps> = (props) => {
    const [disabled, setDisabled] = useState(true)
    const [creds, setCreds] = useState({ password: '', confirmPassword: '' })
    const { updateUserPwd } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const updateCreds = (creds: {
        password: string
        confirmPassword: string
    }) => {
        setCreds(creds)
        setDisabled(
            !creds.password ||
                !creds.confirmPassword ||
                creds.password !== creds.confirmPassword
        )
    }

    const resetPassword = async () => {
        if (!creds.password) return
        try {
            setIsLoading(true)
            const res = await updateUserPwd(creds.password)

            if (res?.error) {
                throw new Error('Error in updating password')
            }
            props.onSuccess()
        } catch (error) {
            const message = (error as unknown as any).message
            props.onError(message)
            console.log('ERROR', error)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div
            className={
                'w-full h-full flex flex-col gap-8 items-center justify-center pt-8 '
            }
        >
            <Logo additionalStyle={{ marginTop: '-60px', width: '200px' }} />
            <TextField
                type={'password'}
                onChange={(val) =>
                    updateCreds({
                        password: val,
                        confirmPassword: creds.confirmPassword,
                    })
                }
                placeholder={'Type new password'}
            />
            <TextField
                type={'password'}
                onChange={(val) =>
                    updateCreds({
                        password: creds.password,
                        confirmPassword: val,
                    })
                }
                placeholder={'Confirm password'}
            />

            <BaseButton
                loading={isLoading}
                onClick={resetPassword}
                disabled={disabled}
                color={'blue'}
            >
                Reset password
            </BaseButton>
        </div>
    )
}

export default PasswordRecoveryForm
