import { IonApp, setupIonicReact } from '@ionic/react'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'
import './theme/fonts.css'

import './theme/globals.css'
import TabRouter from './components/Router/TabRouter'
import { AuthProvider } from './components/AuthContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '/node_modules/flag-icons/css/flag-icons.min.css'
import Analytics from './components/Analytics'

setupIonicReact()

const App: React.FC = () => (
    <Analytics>
        <AuthProvider>
            <div style={{ maxWidth: '768px!important', margin: 'auto' }}>
                <ToastContainer
                    autoClose={3000}
                    icon={false}
                    style={{ zIndex: 100000000000 }}
                />

                <IonApp style={{ maxWidth: '768px', margin: 'auto' }}>
                    <div id={'background-wrapper'}>
                        <TabRouter />
                    </div>
                </IonApp>
            </div>
        </AuthProvider>
    </Analytics>
)

export default App
