import { create } from 'zustand'
import { createQuizSlice, QuizSlice } from './quiz.slice'
import { createUserSlice, UserSlice } from './user.slice'
import { persist } from 'zustand/middleware'
import { createScoreSlice, ScoreSlice } from './score.slice'

interface StoreState extends QuizSlice, UserSlice, ScoreSlice {}

export const useBoundStore = create<StoreState>()(
    persist(
      (...a) => ({
            ...createQuizSlice(...a),
            ...createUserSlice(...a),
            ...createScoreSlice(...a),
        }),
        { name: 'bound-store' }
    )
)
