import { FunctionComponent, useMemo, useState } from 'react'
import SlideWrapper from '../atoms/Slides/SlideWrapper'
import { useTranslation } from 'react-i18next'
import TextField from '../atoms/TextField'
import BaseButton from '../atoms/Button/BaseButton'
import { useSwiper } from 'swiper/react'
import { Country } from '../../types/user'
import countriesList from '../../data/countries.json'

interface IProps {
  onConfirm: (country: Country) => void
}

const AuthCountrySlide: FunctionComponent<IProps> = (props) => {
  const { t } = useTranslation()

  const swiper = useSwiper()

  const [typedCountry, setTypedCountry] = useState<string>()
  const [country, setCountry] = useState<Country>()
  const possibleCountries = useMemo(() => {
    if (!typedCountry) return []
    return countriesList.filter((c) =>
      c.name.toLowerCase().includes(typedCountry.toLowerCase())
    )
  }, [typedCountry])

  const verifyCountry = () => {
    if (!country) return
    props.onConfirm(country)
    swiper.slideNext()
  }
  return (
    <SlideWrapper className={'h-2/3'}>
      <div className={'flex flex-col items-center gap-4'}>
        <h1>{t('your-country')}</h1>
        <h3 className={'text-center'}>
          {t(
            'lead-your-nation-to-victory-in-the-global-quiz-high-scores'
          )}
        </h3>
      </div>
      <div className={'w-full flex flex-col gap-1'}>
        <TextField
          type={'text'}
          value={country?.name || typedCountry}
          onChange={(typedCountry) => {
            setCountry(undefined)
            setTypedCountry(typedCountry)
          }}
          placeholder={t('country')}
        />
        {possibleCountries.length > 0 && !country && (
          <div
            className={
              'flex flex-col rounded-2xl gap-2 w-full bg-black/50 text-white text-xl p-4 font-tilt'
            }
          >
            <ul className={'flex flex-col gap-4'}>
              {possibleCountries.map(
                (country: Country, index: number) => (
                  <li
                    onClick={() => {
                      setCountry(country)
                    }}
                    key={index}
                  >
                    {country.name}
                  </li>
                )
              )}
            </ul>
          </div>
        )}
      </div>

      <BaseButton
        onClick={verifyCountry}
        color={'yellow'}
        disabled={!country}
      >
        {t('continue')}
      </BaseButton>
    </SlideWrapper>
  )
}

export default AuthCountrySlide

