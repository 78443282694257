import { FunctionComponent, useEffect, useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import Header from '../../components/atoms/Header'
import FeedbackSlider from '../../components/FeedbackSlider'
import { useHistory } from 'react-router'
import { fetchUserFeedback } from '../../actions/feedback/fetchUserFeedback.action'
import { useBoundStore } from '../../stores/store'

const Feedback: FunctionComponent = () => {
    const [currentQuestion, setCurrentQuestion] = useState(1)
    const history = useHistory()
    const accessToken = useBoundStore((state) => state.accessToken)
    const handleEnd = () => {
        history.push('/home')
    }
    const userFeedback = async () => {
        const uf = await fetchUserFeedback(accessToken!, 1)

        if (uf) {
            history.push('/stats')
        }
    }

    useEffect(() => {
        if (accessToken) {
            userFeedback()
        }
    }, [accessToken])

    return (
        <IonPage className={'quiz-bg'}>
            <Header hideLogout={true}>
                <div
                    className={
                        'flex flex-col w-full h-full items-center justify-center'
                    }
                >
                    <p
                        className={
                            'text-xl bg-gradient-to-r from-[#532B14] to-[#3C1500] text-transparent bg-clip-text '
                        }
                    >
                        Question
                    </p>
                    <h1 className={'text-xl'}>{currentQuestion}/4</h1>
                </div>
            </Header>
            <IonContent className={'quiz-bg h-slide'}>
                <div className={'w-full h-full '}>
                    <FeedbackSlider
                        onEnd={handleEnd}
                        onNext={(i) => setCurrentQuestion(i)}
                    />
                </div>
            </IonContent>
        </IonPage>
    )
}

export default Feedback
