import { FetchDailyQuizDataResults } from '../actions/quiz/fetchDailyQuiz.action'
import { StateCreator } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface ScoreState {
    choicePercentage: FetchDailyQuizDataResults['choicePercentage']
    top20ByScore: FetchDailyQuizDataResults['top20ByScore']
    questionsTimeSpent?: FetchDailyQuizDataResults['questionsTimeSpent']
    questionsScoreByUser?: FetchDailyQuizDataResults['questionsScoreByUser']
}

type ScoreActions = {
    setChoicesPercentage: (
        results: FetchDailyQuizDataResults['choicePercentage']
    ) => void
    setTop20ByScore: (
        results: FetchDailyQuizDataResults['top20ByScore']
    ) => void
    setQuestionsTimeSpent: (
        results: FetchDailyQuizDataResults['questionsTimeSpent']
    ) => void
    setQuestionsScoreByUser: (
        results: FetchDailyQuizDataResults['questionsScoreByUser']
    ) => void
    clearStats: () => void
}
export type ScoreSlice = ScoreState & ScoreActions

export const createScoreSlice: StateCreator<
    ScoreSlice,
    [],
    [['zustand/immer', never]]
> = immer((set) => ({
    choicePercentage: undefined,
    top20ByScore: undefined,
    questionsTimeSpent: undefined,
    questionsScoreByUser: undefined,
    clearStats: () => {
        set((state) => {
            state.choicePercentage = undefined
            state.top20ByScore = undefined
            state.questionsTimeSpent = undefined
            state.questionsScoreByUser = undefined
        })
    },
    setChoicesPercentage: (results) => {
        console.log('RESULTS', results)
        set((state) => {
            state.choicePercentage = results
        })
    },
    setTop20ByScore: (results) => {
        set((state) => {
            console.log('2222')
            state.top20ByScore = results
        })
    },
    setQuestionsTimeSpent: (results) => {
        set((state) => {
            state.questionsTimeSpent = results
        })
    },
    setQuestionsScoreByUser: (results) => {
        set((state) => {
            state.questionsScoreByUser = results
        })
    },
}))
