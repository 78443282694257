import useSWR from 'swr'
import createFetcher from './fetcher'

interface StreaksResponse {
    streaks: number
}

function useStreaks(accessToken?: string) {
    const fetcher = createFetcher(accessToken!)
    const date = new Date().toISOString().split('T')[0]
    const { data, error, mutate } = useSWR<StreaksResponse>(
        '/streaks-count?date=' + date,
        fetcher
    )

    return {
        streaks: data,
        isLoading: !error && !data,
        isError: error,
        mutate,
    }
}

export default useStreaks
