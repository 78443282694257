import { FunctionComponent } from 'react'

interface IProps {
    text: string
}

const QuestionText: FunctionComponent<IProps> = (props) => {
    return <h1 className={'text-center'}>{props.text}</h1>
}

export default QuestionText
