import { FunctionComponent, ReactNode } from 'react'

interface IProps {
    children: ReactNode
    className?: string
}

const SlideWrapper: FunctionComponent<IProps> = ({ children, className }) => {
    return (
        <div
            className={
                'w-full  flex flex-col items-center gap-8 px-8 ' + className
            }
        >
            {children}
        </div>
    )
}

export default SlideWrapper
