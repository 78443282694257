import { FunctionComponent, useState } from 'react'
import TextField from '../../atoms/TextField'
import Logo from '../../atoms/Logo'
import BaseButton from '../../atoms/Button/BaseButton'
import { supabase } from '../../../utils/supabase.utils'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-tailwind/react'

interface IProps {
    onSuccess: () => void
    onError: (error: string) => void
    onLoginClick: () => void
}

const SignupForm: FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation()
    const [disabled, setDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [creds, setCreds] = useState({
        email: '',
        password: '',
    })

    const signUpUser = async () => {
        try {
            setIsLoading(true)
            const { data, error } = await supabase.auth.signUp({
                email: creds.email,
                password: creds.password,
                options: {
                    emailRedirectTo: process.env.REACT_APP_WEB_URI + '/welcome',
                },
            })

            if (error) {
                throw error
                return
            }

            if (data) {
                props.onSuccess()
            }
        } catch (error) {
            console.log(error)
            const errorMessage = (error as unknown as any).message
            props.onError(errorMessage)
        } finally {
            setIsLoading(false)
        }
    }

    const updateCreds = (email: string, password: string) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        setCreds({ email, password })

        const isAlphaNumeric = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^\w\s]).+$/
        setDisabled(
            email === '' ||
                password === '' ||
                !emailRegex.test(email) ||
                password.length < 6 ||
                !isAlphaNumeric.test(password)
        )
    }

    return (
        <div
            className={
                'w-full h-full flex flex-col gap-8 items-center justify-center pt-8 '
            }
        >
            <Logo additionalStyle={{ marginTop: '-60px', width: '200px' }} />
            <div className={'flex flex-col gap-4 w-full'}>
                <TextField
                    type={'email'}
                    onChange={(val) => updateCreds(val, creds.password)}
                    placeholder={'Email'}
                />
                <div className={'flex flex-col gap-1'}>
                    <TextField
                        type={'password'}
                        placeholder={'Password'}
                        onChange={(val) => updateCreds(creds.email, val)}
                    />
                    <Typography
                        className={'pl-2 text-sm'}
                        placeholder
                        color="red"
                    >
                        {t('password-requirements')}
                    </Typography>
                </div>
            </div>

            <BaseButton
                loading={isLoading}
                onClick={signUpUser}
                disabled={disabled}
                color={'blue'}
            >
                {t('create-account')}
            </BaseButton>

            <h5 className={'font-light text-xl'} onClick={props.onLoginClick}>
                <span>{t('already-account')} </span>{' '}
                <span className={'text-yellow-300'}>{t('login')} </span>
            </h5>
        </div>
    )
}

export default SignupForm
