import { FunctionComponent, useState } from 'react'
import QuizIntro from './Intro'
import { useBoundStore } from '../../stores/store'
import QuizQuestion from './QuizQuestion'
import QuizResults from './Results'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import useStreaks from '../../hooks/useStreaks'
import { fetchUserFeedback } from '../../actions/feedback/fetchUserFeedback.action'

type QuizSteps = 'intro' | 'questions' | 'results'
const DailyQuizWizard: FunctionComponent = () => {
    const history = useHistory()
    const { t } = useTranslation()
    const { mutate } = useStreaks()
    const [isSaving, setIsSaving] = useState(false)
    const quizStep = useBoundStore((state) => state.step)
    const userQuiz = useBoundStore((state) => state.quiz)
    const userScore = useBoundStore((state) => state.userScore)
    const accessToken = useBoundStore((state) => state.accessToken)
    const user = useBoundStore((state) => state.user)

    const redirectToFeedback = async () => {
        setIsSaving(true)
        const startedFunctionTime = new Date().getTime()
        if (!userQuiz || !accessToken) return
        try {
            const userFeedback = await fetchUserFeedback(accessToken!, 1)
            if (userFeedback) {
                history.push('/stats')
            } else {
                history.push('/feedback')
            }
        } catch (e) {
            console.error(e)
            toast((e as any).message, { type: 'error' })
        } finally {
            setIsSaving(false)
        }
    }

    return (
        <div
            className={
                'h-full w-screen flex flex-col items-center justify-around '
            }
        >
            {quizStep === 'intro' && <QuizIntro />}
            {quizStep === 'questions' && <QuizQuestion />}
            {quizStep === 'results' && (
                <QuizResults
                    onContinue={redirectToFeedback}
                    isSaving={isSaving}
                />
            )}
        </div>
    )
}

export default DailyQuizWizard
