import { FunctionComponent, useState } from 'react'
import TextField from '../../atoms/TextField'
import Logo from '../../atoms/Logo'
import BaseButton from '../../atoms/Button/BaseButton'
import { useAuth } from '../../AuthContext'

interface IProps {
    onSuccess: () => void
    onError: () => void
}

const ForgotPasswordForm: FunctionComponent<IProps> = (props) => {
    const [disabled, setDisabled] = useState(true)
    const [email, setEmail] = useState<string>()
    const { sendPasswordResetEmail } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const updateCreds = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        setEmail(email)
        setDisabled(email === '' || !emailRegex.test(email))
    }

    const resetPassword = async () => {
        if (!email) return
        try {
            setIsLoading(true)
            const { data, error } = await sendPasswordResetEmail(email)

            if (error) {
                throw new Error('Error sending password reset email')
            }
            props.onSuccess()
        } catch (error) {
            props.onError()
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div
            className={
                'w-full h-full flex flex-col gap-8 items-center justify-center pt-8 '
            }
        >
            <Logo additionalStyle={{ marginTop: '-60px', width: '200px' }} />
            <TextField
                type={'email'}
                onChange={(val) => updateCreds(val)}
                placeholder={'Email'}
            />

            <BaseButton
                loading={isLoading}
                onClick={resetPassword}
                disabled={disabled}
                color={'blue'}
            >
                Reset password
            </BaseButton>
        </div>
    )
}

export default ForgotPasswordForm
