import { FunctionComponent, useState } from 'react'
import TextField from '../../atoms/TextField'
import Logo from '../../atoms/Logo'
import BaseButton from '../../atoms/Button/BaseButton'
import { useAuth } from '../../AuthContext'
import { useTranslation } from 'react-i18next'

interface IProps {
    onForgotPassword: () => void
    onSignUp: () => void

    onLoginSuccess: () => void

    onLoginError: (message: string) => void
}

const LoginForm: FunctionComponent<IProps> = (props) => {
    const [disabled, setDisabled] = useState(true)
    const { signInWithEmail } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [creds, setCreds] = useState({
        email: '',
        password: '',
    })

    const { t } = useTranslation()
    const loginPlainUser = async () => {
        try {
            setIsLoading(true)
            const { data, error } = await signInWithEmail(
                creds.email,
                creds.password
            )
            if (data.session) {
                props.onLoginSuccess()
            } else {
                throw error
            }
        } catch (error) {
            console.log('ERROR')
            const errorMessage = (error as unknown as any).message
            props.onLoginError(errorMessage)
        } finally {
            setIsLoading(false)
        }
    }

    const updateCreds = (email: string, password: string) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
        setCreds({ email, password })
        setDisabled(email === '' || password === '' || !emailRegex.test(email))
    }

    return (
        <div
            className={
                'w-full h-full flex flex-col gap-8 items-center justify-center pt-8 '
            }
        >
            <Logo additionalStyle={{ marginTop: '-60px', width: '200px' }} />
            <div className={'flex flex-col gap-4 w-full'}>
                <TextField
                    type={'email'}
                    onChange={(val) => updateCreds(val, creds.password)}
                    placeholder={'Email'}
                />
                <TextField
                    type={'password'}
                    placeholder={'Password'}
                    onChange={(val) => updateCreds(creds.email, val)}
                />
            </div>

            <BaseButton
                loading={isLoading}
                onClick={loginPlainUser}
                disabled={disabled}
                color={'blue'}
            >
                {t('login')}
            </BaseButton>
            <div className={'flex flex-col gap-5 items-center text-metal-blue'}>
                <h5 className={'font-light text-xl'} onClick={props.onSignUp}>
                    <span>{t('no-account')} </span>{' '}
                    <span className={'text-yellow-300'}>
                        {t('create-one')}{' '}
                    </span>
                </h5>
                <h5
                    className={'text-yellow-300 font-light text-xl'}
                    onClick={props.onForgotPassword}
                >
                    {t('reset-password')}
                </h5>
            </div>
        </div>
    )
}

export default LoginForm
