export const calculateUserScore = (timeTakenMillis: number) => {
  const maxTime = 5; // Maximum time for 0 score
  const timeTaken = timeTakenMillis / 1000; // Convert milliseconds to seconds

  if (timeTaken >= maxTime) {
    return 0;
  }

  const maxScore = 1000;
  const baseValue = 1; // To ensure we don't take log of 0
  // Adjusting the formula to use a logarithmic scale inversely related to timeTaken
  let adjustedTime = maxTime - timeTaken + baseValue;
  let score = (Math.log(adjustedTime) / Math.log(maxTime + baseValue)) * maxScore;

  return Math.max(0, Math.round(score));
}
