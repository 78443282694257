import { Quiz, UserDailyQuizResults } from '../../types/quiz'

export const saveDailyQuizUserResults: (
    accessToken: string,
    dailyQuizResults: UserDailyQuizResults
) => Promise<Quiz> = async (accessToken, dailyQuizResults) => {
    // date in milliseconds
    const saveDailyQuizResults = await fetch(
        process.env.REACT_APP_EDGE_FUNCTIONS_URI + '/user-results',
        {
            method: 'POST',
            body: JSON.stringify(dailyQuizResults),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        }
    )

    if (saveDailyQuizResults.status !== 201) {
        console.error(
            'Error saving daily quiz results',
            saveDailyQuizResults.status,
            saveDailyQuizResults.statusText
        )
        throw new Error('Error saving daily quiz results')
    }

    const res = await saveDailyQuizResults.json()

    return res
}
