import { FunctionComponent } from 'react'
import { Redirect } from 'react-router'
import { Route } from 'react-router-dom'
import { useBoundStore } from '../../stores/store'

interface IProps {
    path: string

    component: JSX.Element
}

const QuizGateRoute: FunctionComponent<IProps> = (authProps) => {
    const quizData = useBoundStore((state) => state.quizData)

    const renderFunction = (props: any) => {
        if (!quizData) {
            return <Redirect to={'/'} />
        }

        return authProps.component
    }

    return <Route path={authProps.path} render={renderFunction} />
}

export default QuizGateRoute
