import useSWR from 'swr'
import createFetcher from './fetcher'
import { FetchDailyQuizDataResults } from '../actions/quiz/fetchDailyQuiz.action'

const date = new Date().getTime()

export function shuffleArray(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
}

function useDailyQuiz(accessToken?: string, shouldFetch?: boolean) {
    if (!accessToken) throw new Error('No access token provided')
    const fetcher = createFetcher(accessToken)
    const {
        data: quizData,
        error,
        mutate,
    } = useSWR<FetchDailyQuizDataResults>(
        shouldFetch ? '/daily-quiz?date=' + date : null,
        fetcher,
        {}
    )

    return {
        quizData: quizData,
        isLoading: (!error && !quizData) || !shouldFetch,
        isError: error,
        refresh: mutate,
        shuffleArray,
    }
}

export default useDailyQuiz
