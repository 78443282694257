import UserAvatar from '../UserAvatar'

const Loading: React.FC = () => {
    return (
        <div
            className={
                'flex flex-col w-full h-full items-center justify-center'
            }
        >
            <UserAvatar
                avatarId={1}
                additionalClasses={'w-20 h-20 animate-ping'}
            />
        </div>
    )
}

export default Loading
