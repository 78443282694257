import { FunctionComponent, memo, useState } from 'react'

export interface ComposableFiltersProps {
    labels: {
        name: string
        value: string
    }[]

    onChange: (value: string) => void
}

const ComposableFilters: FunctionComponent<ComposableFiltersProps> = memo(
    (props) => {
        const [activeLabel, setActiveLabel] = useState<string>(
            props.labels[0].name
        )

        return (
            <div className="w-full px-8 py-2 flex flex-row justify-between items-center stats-card">
                {props.labels.map((label) => (
                    <button
                        key={label.name}
                        onClick={() => {
                            setActiveLabel(label.name)
                            props.onChange(label.name)
                        }}
                        className={`${
                            activeLabel === label.name
                                ? 'text-white '
                                : 'text-gray-700 bg-transparent'
                        } font-medium text-lg capitalize transition-colors duration-300 ease-in-out`}
                    >
                        {label.value}
                    </button>
                ))}
            </div>
        )
    }
)

export default ComposableFilters
