interface ResponseWithError<T> {
  data: T | null
  error: any | null
}

export const checkExistingUsername = async (
  accessToken: string,
  username: string
): Promise<ResponseWithError<{ message: string }>> => {
  const userRequest = await fetch(
    process.env.REACT_APP_EDGE_FUNCTIONS_URI + '/username-check',
    {
      method: 'POST',
      body: JSON.stringify({ username: username.toLowerCase() }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )

  if (userRequest.status === 201 || userRequest.status === 200) {
    return { data: await userRequest.json(), error: null }
  } else {
    console.error(userRequest)
    return { data: null, error: new Error('Username already exists') }
  }
}
